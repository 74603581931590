import { useEffect, useState } from "react";
import { Form, Button, InputGroup, Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updatePassengerStatus } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { useForm } from "react-hook-form";

const PassengerStatusModal = ({ show, setShow, id }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const handleClose = () => {
    reset();
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false);
  };

  //Form initiation
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    defaultValues: {
      passenger_status: "",
      blocked_reason: "",
      id: id,
    },
  });

  const watchPassengerStatus = watch("passenger_status", "");

  const onSubmit = (data) => {
    passengerStatusMutation.mutate(data);
  };

  // Queries & Mutations
  const queryClient = useQueryClient();

  //Update passenger Status
  const passengerStatusMutation = useMutation({
    mutationFn: (data) => updatePassengerStatus(data),
    onSuccess: (dataResponse) => {
      setErrorMessage("");
      setSuccessMessage("Updated successfully!");
      queryClient.invalidateQueries("user");
    },
    onError(error: any) {
      setSuccessMessage("");
      setErrorMessage(Functions.handleError(error));
    },
  });

  //Effects
  useEffect(() => {
    setValue("blocked_reason", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchPassengerStatus]);

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        contentClassName="bg-white"
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Passenger Status</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
            {successMessage && (
              <span className="text-primary">{successMessage}</span>
            )}
            <Form.Group controlId="passenger-status" className="mt-2">
              <Form.Label>Passenger Status</Form.Label>
              <InputGroup className="mb-3">
                <Form.Select
                  {...register("passenger_status", {
                    required: "Passenger Status is required",
                  })}
                >
                  <option value="">Passenger Status</option>
                  <option value="verified">Verified</option>
                  <option value="pending">Pending</option>
                  <option value="unverified">Unverified</option>
                  <option value="blocked">Blocked</option>
                </Form.Select>
              </InputGroup>
              {errors?.passenger_status && (
                <span className="text-danger">
                  {errors?.passenger_status?.message}
                </span>
              )}
            </Form.Group>
            {watchPassengerStatus === "blocked" && (
              <Form.Group controlId="blocked-reason" className="mt-2">
                <Form.Label>Blocked Reason</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Blocked reason"
                  {...register("blocked_reason")}
                />
                {errors?.blocked_reason && (
                  <span className="text-danger">
                    {errors?.blocked_reason?.message}
                  </span>
                )}
              </Form.Group>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default PassengerStatusModal;
