import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Spinner,
  Badge,
  Image,
  Table,
  Row,
  Col,
  Card,
  Tab,
  Nav,
  Button,
  InputGroup,
  FormControl,
  Dropdown,
  Form,
} from "react-bootstrap";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";
import { getPartner, getPartnerLocations } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";
import numbro from "numbro";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";
import AddLocationModal from "./AddLocationModal";
import EditLocationModal from "./EditLocationModal";
import DeleteLocationModal from "./DeleteLocationModal";

const Partner = () => {
  let { id } = useParams();

  //State
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEditLocation, setShowEditLocation] = useState(false);
  const [showDeleteLocation, setShowDeleteLocation] = useState(false);
  const [locationItem, setLocationItem] = useState();
  const [showAddLocation, setShowAddLocation] = useState(false);

  // Queries & Mutations
  //Ger User
  const { isLoading, error, isError, data } = useQuery({
    queryKey: ["partner", id],
    queryFn: () => getPartner({ id: id }),
  });

  //Get Partner Locations
  const {
    data: dataLocations,
    error: errorLocations,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["partner-locations", searchQuery],
    queryFn: ({ pageParam = 0 }) =>
      getPartnerLocations({
        page: pageParam,
        search: searchQuery,
        id: id,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.data.length < 20) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  //Functions
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchQuery(searchInput);
  };

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (isError) {
    return <p className="text-center"> {Functions.handleError(error)}</p>;
  }

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <LinkContainer to={"/dashboard/partners"}>
            <p className="text-primary breadcrumb-text fw-bold">Partners</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">{id}</p>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <p className="h2">{data?.company_name}</p>
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>
            <Dropdown.Menu className="bg-white">
              <Dropdown.Item onClick={() => setShowEdit(true)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowDelete(true)}>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Row className="px-md-5 px-2">
        <Col md="8">
          <Card bg="white">
            <Card.Body>
              <div className="d-flex flex-row align-items-center border-bottom border-dashed pb-4">
                {data?.picture !== null ? (
                  <Image
                    src={process.env.REACT_APP_IMAGE_URL + data?.picture}
                    rounded
                    height="150"
                    className="mr-3"
                  />
                ) : (
                  "No Image"
                )}
                <div className="ms-3">
                  <p>{data?.email}</p>
                  <p className="mb-0">{data?.address}</p>
                  <p className="mb-0">{data?.notes}</p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between pt-4">
                <div>
                  <p className="breadcrumb-text fw-bold lh-sm my-1 py-0">
                    Partner Created
                  </p>
                  <p className="lh-sm my-0 py-0">
                    {DateTime.fromISO(data?.created_at).toLocaleString(
                      DateTime.DATETIME_MED
                    )}
                  </p>
                </div>
                <div>
                  <p className="breadcrumb-text fw-bold lh-sm my-1 py-0">
                    Last Update
                  </p>
                  {data?.updated_at !== null ? (
                    <p className="lh-sm my-0 py-0">
                      {DateTime.fromISO(data?.updated_at).toLocaleString(
                        DateTime.DATETIME_MED
                      )}
                    </p>
                  ) : (
                    <p className="lh-sm my-0 py-0">No updates</p>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mt-2 mt-md-0">
          <Card className="mb-3" bg="white">
            <Card.Body>
              <h4>Status</h4>
              <div className="border-bottom border-dashed pb-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Status</p>
                  <Badge
                    bg={
                      data?.status === "completed"
                        ? "primary"
                        : data?.status === "cancelled"
                        ? "danger"
                        : "secondary"
                    }
                  >
                    {data?.status}
                  </Badge>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Type</p>
                  <Badge bg="primary">{data?.type}</Badge>
                </div>
              </div>
              <div className="pt-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Is Archived</p>
                  <Badge bg={data?.is_archived ? "primary" : "secondary"}>
                    {data?.is_archived ? "True" : "False"}
                  </Badge>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <p className="h2">Partner Locations</p>
          <Button
            variant="outline-secondary"
            onClick={() => setShowAddLocation(true)}
          >
            Add New
          </Button>
        </div>
        <Form className="mb-3" onSubmit={handleSearchSubmit}>
          <Row>
            <Col md={4}>
              <InputGroup size="sm">
                <FormControl
                  type="search"
                  placeholder="Search by name"
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button variant="secondary" type="submit">
                  Search Locations
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
      {status === "error" ? (
        <p className="text-center"> {Functions.handleError(errorLocations)}</p>
      ) : status === "pending" ? null : (
        <div className="px-md-5 px-2 mt-md-2 mt-1 bg-white table-header">
          <Table responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Picture</th>
                <th>Name</th>
                <th>Description</th>
                <th>Contacts</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Is Archived</th>
                <th>Archived At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {dataLocations.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.data.map((item) => (
                    <tr key={item?.id}>
                      <td className="table-text">{item?.id}</td>
                      <td className="table-text text-secondary">
                        {item?.picture}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.name}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.description}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.contacts?.length > 0 &&
                          item?.contacts?.map((item, index) => {
                            return (
                              <Badge bg="secondary" className="mt-2 me-2">
                                {item?.title} {item?.name} ({item?.phone})
                              </Badge>
                            );
                          })}
                      </td>

                      <td className="table-text text-secondary">
                        {DateTime.fromISO(item?.created_at).toLocaleString(
                          DateTime.DATE_MED
                        )}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.updated_at !== null
                          ? DateTime.fromISO(item?.updated_at).toLocaleString(
                              DateTime.DATE_MED
                            )
                          : "No update"}
                      </td>
                      <td className="table-text text-secondary">
                        <Badge bg="secondary" className="mt-2">
                          {item?.is_archived ? "TRUE" : "FALSE"}
                        </Badge>
                      </td>
                      <td className="table-text text-secondary">
                        {item?.archived_at !== null
                          ? DateTime.fromISO(item?.archived_at).toLocaleString(
                              DateTime.DATE_MED
                            )
                          : null}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id="dropdown-basic"
                          >
                            Actions
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="bg-white">
                            <Dropdown.Item
                              onClick={() => {
                                setLocationItem(item);
                                setShowEditLocation(true);
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setLocationItem(item);
                                setShowDeleteLocation(true);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <div className="py-2 d-flex flex-row justify-content-center">
        <Button
          variant="outline-secondary"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "No More Results"}
        </Button>
      </div>
      <div className="py-2 d-flex flex-row justify-content-center">
        {(isFetching || status === "pending") && !isFetchingNextPage ? (
          <Spinner animation="border" />
        ) : null}
      </div>
      <EditModal setShow={setShowEdit} show={showEdit} itemData={data} />

      <DeleteModal setShow={setShowDelete} show={showDelete} itemData={data} />
      <AddLocationModal
        setShow={setShowAddLocation}
        show={showAddLocation}
        itemData={data}
      />
      <EditLocationModal
        setShow={setShowEditLocation}
        show={showEditLocation}
        itemData={locationItem}
      />
      <DeleteLocationModal
        setShow={setShowDeleteLocation}
        show={showDeleteLocation}
        itemData={locationItem}
      />
    </>
  );
};

export default Partner;
