import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  ListGroup,
  Spinner,
  Badge,
  Image,
  Table,
  Row,
  Col,
  Card,
  Tab,
  Nav,
  Dropdown,
} from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { getUser } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";
import numbro from "numbro";
import DriverStatusModal from "./DriverStatusModal";
import PassengerStatusModal from "./PassengerStatusModal";
import AddCreditsModal from "./AddCreditsModal";
import AddCashModal from "./AddCashModal";

const User = () => {
  let { id } = useParams();

  //State
  const [showDS, setShowDS] = useState(false);
  const [showPS, setShowPS] = useState(false);
  const [showCM, setShowCM] = useState(false);
  const [showACM, setShowACM] = useState(false);

  // Queries & Mutations
  //Ger User
  const { isLoading, error, isError, data } = useQuery({
    queryKey: ["user", id],
    queryFn: () => getUser({ id: id }),
  });

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (isError) {
    return <p className="text-center"> {Functions.handleError(error)}</p>;
  }

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <LinkContainer to={"/dashboard/users"}>
            <p className="text-primary breadcrumb-text fw-bold">Users</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">{id}</p>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <p className="h2">Profile</p>
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-white">
              <Dropdown.Item onClick={() => setShowDS(true)}>
                Update Driver Status
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowPS(true)}>
                Update Passenger Status
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowCM(true)}>
                Add Credits
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowACM(true)}>
                Add Cash
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Row className="px-md-5 px-2">
        <Col md="8">
          <Card bg="white">
            <Card.Body>
              <div className="d-flex flex-row align-items-center border-bottom border-dashed pb-4">
                {data?.picture !== null ? (
                  <Image
                    src={process.env.REACT_APP_IMAGE_URL + data?.picture}
                    roundedCircle
                    width="150"
                    height="150"
                    className="mr-3"
                  />
                ) : null}
                <div className="ms-3">
                  <h3>{data?.name}</h3>
                  <p>{data?.phone}</p>
                  <div className="d-flex flex-row">
                    <p className="mx-1">
                      <span className="fw-bold">Role</span> ({data?.role})
                    </p>
                    <p className="mx-1">
                      <span className="fw-bold">Country</span> (
                      {data?.countryDetail?.name})
                    </p>
                    <p className="mx-1">
                      <span className="fw-bold">Gender</span> ({data?.gender})
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between pt-4">
                <div>
                  <p className="breadcrumb-text fw-bold lh-sm my-1 py-0">
                    Token Status
                  </p>
                  <p className="lh-sm my-0 py-0">
                    {data?.token_verified
                      ? "Token Verified"
                      : `Token Unverified(${data?.token})`}
                  </p>
                </div>
                <div>
                  <p className="breadcrumb-text fw-bold lh-sm my-1 py-0">
                    Account Created
                  </p>
                  <p className="lh-sm my-0 py-0">
                    {DateTime.fromISO(data?.created_at).toLocaleString(
                      DateTime.DATETIME_MED
                    )}
                  </p>
                </div>
                <div>
                  <p className="breadcrumb-text fw-bold lh-sm my-1 py-0">
                    Account Updated
                  </p>
                  {data?.updated_at !== null ? (
                    <p className="lh-sm my-0 py-0">
                      {DateTime.fromISO(data?.updated_at).toLocaleString(
                        DateTime.DATETIME_MED
                      )}
                    </p>
                  ) : (
                    <p className="lh-sm my-0 py-0">No updates</p>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mt-2 mt-md-0">
          <Card className="mb-3" bg="white">
            <Card.Body>
              <h4>Verification Center</h4>
              <div className="border-bottom border-dashed pb-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Rating</p>
                  <p>
                    {numbro(data?.average_rating).format({
                      mantissa: 1,
                    })}{" "}
                    ({data?.rating_count})
                  </p>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Driver Status</p>
                  <Badge
                    bg={
                      data?.driver_status === "verified"
                        ? "primary"
                        : data?.driver_status === "unverified"
                        ? "secondary"
                        : "danger"
                    }
                  >
                    {data?.driver_status}
                  </Badge>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Passenger Status</p>
                  <Badge
                    bg={
                      data?.passenger_status === "verified"
                        ? "primary"
                        : data?.passenger_status === "unverified"
                        ? "secondary"
                        : "danger"
                    }
                  >
                    {data?.passenger_status}
                  </Badge>
                </div>
                {(data?.passenger_status === "blocked" ||
                  data?.driver_status === "blocked") && (
                  <div className="d-flex flex-row align-items-center justify-content-between">
                    <p>Reason for Blocking:</p>
                    <p>{data?.blocked_reason}</p>
                  </div>
                )}
              </div>
              <div className="pt-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Is Admin</p>
                  <Badge bg={data?.is_admin ? "primary" : "secondary"}>
                    {data?.is_admin ? "True" : "False"}
                  </Badge>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Is Deleted</p>
                  <Badge bg={data?.is_deleted ? "primary" : "secondary"}>
                    {data?.is_deleted ? "True" : "False"}
                  </Badge>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mx-5">
        <Col>
          <Tab.Container id="left-tabs-example" defaultActiveKey="payments">
            <Nav variant="underline" className="pb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="payments">Wallet</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ratings">Ratings</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="vehicles">Vehicles</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="payments" title="Payments">
                <Card className="mb-3">
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <p>
                          Wallet Credits:{" "}
                          {numbro(data?.wallet_credits).format({
                            thousandSeparated: true,
                          })}{" "}
                          {data?.countryDetail?.currency_symbol}
                        </p>
                      </div>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <div className="d-flex flex-row justify-content-between align-items-center">
                        <p>
                          Wallet Balance:{" "}
                          {numbro(data?.wallet_balance).format({
                            thousandSeparated: true,
                          })}{" "}
                          {data?.countryDetail?.currency_symbol}
                        </p>
                      </div>
                    </ListGroup.Item>
                  </ListGroup>
                </Card>
              </Tab.Pane>
              <Tab.Pane eventKey="vehicles" title="Vehicles">
                {data?.vehicleDetail?.length > 0 ? (
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Model</th>
                        <th>Number Plate</th>
                        <th>Category</th>
                        <th>Brand</th>
                        <th>Color</th>
                        <th>Is Deleted</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.vehicleDetail?.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td className="table-text">{item.id}</td>
                            <td className="table-text">{item.model}</td>
                            <td className="text-secondary table-text">
                              {item.number_plate}
                            </td>
                            <td className="table-text">
                              <Badge bg="secondary">
                                {item?.carCategoryDetail?.name}
                              </Badge>
                            </td>
                            <td className="table-text">
                              <Badge bg="secondary">
                                {item?.carBrandDetail?.name}
                              </Badge>
                            </td>
                            <td className="table-text">
                              <Badge bg="secondary">
                                {item?.carColorDetail?.name}
                              </Badge>
                            </td>
                            <td className="table-text">
                              {" "}
                              <Badge
                                bg={item.is_archived ? "secondary" : "primary"}
                              >
                                {item.is_archived ? "deleted" : "active"}
                              </Badge>
                            </td>
                            <td className="table-text">
                              {" "}
                              {DateTime.fromISO(
                                item?.created_at
                              ).toLocaleString(DateTime.DATETIME_MED)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <p>No vehicles found.</p>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="ratings" title="Ratings">
                Ratings to be added here
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
      <DriverStatusModal setShow={setShowDS} show={showDS} id={id} />
      <PassengerStatusModal setShow={setShowPS} show={showPS} id={id} />
      <AddCreditsModal setShow={setShowCM} show={showCM} id={id} />
      <AddCashModal setShow={setShowACM} show={showACM} id={id} />
    </>
  );
};

export default User;
