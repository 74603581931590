import React, { useState } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import {
  Spinner,
  Badge,
  Image,
  Table,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { getBookings, getBookingStats } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";
import numbro from "numbro";

const Bookings = () => {
  const history = useHistory();
  //State
  const [bookingStatus, setBookingStatus] = useState(null);
  const [isArchived, setIsArchived] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  //Queries & Mutations
  //Get Bookings
  const {
    data,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["bookings", bookingStatus, isArchived, searchQuery],
    queryFn: ({ pageParam = 0 }) =>
      getBookings({
        page: pageParam,
        status: bookingStatus,
        is_archived: isArchived,
        search: searchQuery,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.data.length < 20) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  //Get Stats
  const {
    isLoading: isLoadingStats,
    isError: isErrorStats,
    data: dataStats,
  } = useQuery({
    queryKey: ["bookingStats"],
    queryFn: () => getBookingStats(),
  });

  //Functions
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchQuery(searchInput);
  };

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">Bookings</p>
        </div>
        <p className="h2">Bookings</p>
        {isLoadingStats ? null : isErrorStats ? (
          <p className="breadcrumb-text">Error loading stats</p>
        ) : (
          <div className="d-flex flex-row align-items-center mt-2">
            <p className="breadcrumb-text mx-3">
              <span className="fw-bold">All</span> ({dataStats?.total_bookings})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setBookingStatus("pending")}
            >
              <span className="fw-bold text-primary">Pending</span> (
              {dataStats?.pending_bookings})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setBookingStatus("accepted")}
            >
              <span className="fw-bold text-primary">Accepted</span> (
              {dataStats?.accepted_bookings})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setBookingStatus("rejected")}
            >
              <span className="fw-bold text-primary">Rejected</span> (
              {dataStats?.rejected_bookings})
            </p>

            <p
              className="breadcrumb-text mx-3"
              onClick={() => setBookingStatus("cancelled")}
            >
              <span className="fw-bold text-primary">Cancelled</span> (
              {dataStats?.cancelled_bookings})
            </p>
          </div>
        )}
        <Form className="mb-3" onSubmit={handleSearchSubmit}>
          <Row>
            <Col md={4}>
              <InputGroup size="sm">
                <FormControl
                  type="search"
                  placeholder="Search by booking id, ride id or Name"
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button variant="secondary" type="submit">
                  Search
                </Button>
              </InputGroup>
            </Col>
            <Col md={6} className="mt-2 mt-md-0">
              <InputGroup className="mb-3">
                <Form.Select
                  size="sm"
                  value={bookingStatus}
                  onChange={(e) => setBookingStatus(e.target.value)}
                >
                  <option value="">Booking Status</option>
                  <option value="pending">Pending</option>
                  <option value="accepted">Accepted</option>
                  <option value="rejected">Rejected</option>
                  <option value="cancelled">Cancelled</option>
                </Form.Select>

                <Form.Select
                  size="sm"
                  value={isArchived}
                  onChange={(e) => setIsArchived(e.target.value)}
                >
                  <option value="">Is Archived</option>
                  <option value="t">True</option>
                  <option value="f">False</option>
                </Form.Select>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    setIsArchived("");
                    setBookingStatus("");
                    setSearchInput("");
                    setSearchQuery("");
                  }}
                >
                  Clear Filters
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
      {status === "error" ? (
        <p className="text-center"> {Functions.handleError(error)}</p>
      ) : status === "pending" ? null : (
        <div className="px-md-5 px-2 mt-md-2 mt-1 bg-white table-header">
          <Table responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Passenger</th>
                <th>Route</th>
                <th>Seats</th>
                <th>Cost</th>
                <th>Status</th>
                <th>Departure</th>
                <th>Booked On</th>
              </tr>
            </thead>
            <tbody>
              {data.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.data.map((item) => (
                    <tr
                      key={item?.id}
                      onClick={() =>
                        history.push(`/dashboard/bookings/${item?.id}`)
                      }
                    >
                      <td className="table-text">{item?.id}</td>
                      <td className="table-text text-secondary d-flex flex-row align-items-center">
                        {item?.owner?.picture !== null ? (
                          <div>
                            <Image
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                item?.owner?.picture
                              }
                              roundedCircle
                              width="50"
                              height="50"
                              className="mr-3"
                            />
                          </div>
                        ) : null}
                        <div className="ms-2">
                          <p className="text-secondary table-text mb-0">
                            {item?.owner?.name}
                          </p>
                          <p className="text-secondary breadcrumb-text mb-0">
                            {item?.owner?.phone}
                          </p>
                          <p className="text-secondary breadcrumb-text mb-0">
                            {item?.owner?.gender} |{" "}
                            {item?.owner?.average_rating} (
                            {item?.owner?.rating_count})
                          </p>
                        </div>
                      </td>
                      <td className="table-text">
                        <span className="fw-bold">
                          {item?.ride?.originDetail?.name}
                        </span>{" "}
                        {" > "}{" "}
                        <span className="fw-bold">
                          {item?.ride?.destinationDetail?.name}
                        </span>
                      </td>
                      <td className="text-secondary table-text">
                        {item?.seats}
                      </td>
                      <td className="table-text">
                        {" "}
                        {numbro(item?.amount).format({
                          thousandSeparated: true,
                        })}{" "}
                        UGX
                      </td>

                      <td className="table-text">
                        {" "}
                        <Badge
                          bg={
                            item?.status === "accepted"
                              ? "primary"
                              : item?.status === "pending"
                              ? "secondary"
                              : "danger"
                          }
                          className="mt-2"
                        >
                          {item?.status}
                        </Badge>
                      </td>

                      <td className="table-text text-secondary">
                        {item?.ride?.departure !== null
                          ? DateTime.fromISO(
                              item?.ride?.departure
                            ).toLocaleString(DateTime.DATETIME_MED)
                          : "No update"}
                      </td>
                      <td className="table-text text-secondary">
                        {DateTime.fromISO(item?.created_at).toLocaleString(
                          DateTime.DATETIME_MED
                        )}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <div className="py-2 d-flex flex-row justify-content-center">
        <Button
          variant="outline-secondary"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "No More Results"}
        </Button>
      </div>
      <div className="py-2 d-flex flex-row justify-content-center">
        {(isFetching || isLoadingStats || status === "pending") &&
        !isFetchingNextPage ? (
          <Spinner animation="border" />
        ) : null}
      </div>
    </>
  );
};

export default Bookings;
