import { useEffect, useState } from "react";
import { Form, Button, InputGroup, Modal, Alert } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addMessage, getMessageCounts } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { useForm } from "react-hook-form";

const AddMessage = ({ show, setShow }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [messageCountData, setMessageCountData] = useState(null);
  const [errorCountMessage, setErrorCountMessage] = useState("");
  const [successCountMessage, setSuccessCountMessage] = useState("");
  const [charCount, setCharCount] = useState(160);

  const handleClose = () => {
    reset();
    setSuccessMessage("");
    setErrorMessage("");
    setErrorCountMessage("");
    setSuccessCountMessage("");
    setMessageCountData(null);

    setShow(false);
  };

  //Form initiation
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    defaultValues: {
      message: "",
      send_notification: "",
      send_sms: "",
      driver_status: "",
      passenger_status: "",
      profile_status: "",
      active_status: "",
    },
  });

  const watchOptions = watch([
    "driver_status",
    "passenger_status",
    "profile_status",
    "active_status",
  ]);

  const watchMessage = watch("message", "");

  useEffect(() => {
    setCharCount(160 - watchMessage.length);
  }, [watchMessage]);

  const onGetMessageCounts = () => {
    let params = {
      driver_status: watchOptions[0],
      passenger_status: watchOptions[1],
      profile_status: watchOptions[2],
      active_status: watchOptions[3],
    };
    getMessageCountsMutation.mutate(params);
  };

  const onSubmit = (data) => {
    let params = {
      message: data.message,
      send_notification: data.send_notification === true ? "t" : "f",
      send_sms: data.send_sms === true ? "t" : "f",
      driver_status: data.driver_status,
      passenger_status: data.passenger_status,
      profile_status: data.profile_status,
      active_status: data.active_status,
    };
    addMessageMutation.mutate(params);
  };

  // Queries & Mutations
  const queryClient = useQueryClient();

  //Update Status
  const getMessageCountsMutation = useMutation({
    mutationFn: (data) => getMessageCounts(data),
    onSuccess: (dataResponse) => {
      setMessageCountData(dataResponse);
      setErrorCountMessage("");
      setSuccessCountMessage("Message count received.");
    },
    onError(error: any) {
      setMessageCountData(null);
      setSuccessCountMessage("");
      setErrorCountMessage(Functions.handleError(error));
    },
  });

  const addMessageMutation = useMutation({
    mutationFn: (data) => addMessage(data),
    onSuccess: (dataResponse) => {
      setErrorCountMessage("");
      setSuccessCountMessage("");
      setErrorMessage("");
      setSuccessMessage("Updated successfully!");
      queryClient.invalidateQueries("user");
    },
    onError(error: any) {
      setErrorCountMessage("");
      setSuccessCountMessage("");
      setSuccessMessage("");
      setErrorMessage(Functions.handleError(error));
    },
  });

  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        contentClassName="bg-white"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send SMS & Notification</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
            {successMessage && (
              <span className="text-primary">{successMessage}</span>
            )}
            {errorCountMessage && (
              <span className="text-danger">{errorCountMessage}</span>
            )}
            {successCountMessage && (
              <span className="text-primary">{successCountMessage}</span>
            )}

            <Form.Group controlId="message" className="mt-2">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                {...register("message", {
                  required: "Message is required",
                  maxLength: {
                    value: 160,
                    message:
                      "Message cannot exceed 160 characters (SMS Length)",
                  },
                })}
                rows={3}
                maxLength="160"
                placeholder="Type your message here..."
              />

              <Form.Text className="text-primary">
                {charCount} characters remaining
              </Form.Text>
              {errors?.driver_status && (
                <span className="text-danger">{errors?.message?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="message" className="mt-2">
              <Form.Label>Message Type</Form.Label>
              <Form.Check // prettier-ignore
                type="switch"
                id="send-sms-switch"
                label="Send SMS Message"
                {...register("send_sms")}
                onChange={(e) => setValue("send_sms", e.target.checked)}
              />
              <Form.Check // prettier-ignore
                type="switch"
                id="send-notification-switch"
                label="Send Notification"
                {...register("send_notification")}
                onChange={(e) =>
                  setValue("send_notification", e.target.checked)
                }
              />
            </Form.Group>
            <Form.Group controlId="ride-status" className="mt-2">
              <Form.Label>Driver Status</Form.Label>
              <InputGroup className="mb-3">
                <Form.Select {...register("driver_status")}>
                  <option value="">All Statuses</option>
                  <option value="unverified">Unverified</option>
                  <option value="verified">Verified</option>
                  <option value="pending">Pending</option>
                  <option value="blocked">Blocked</option>
                </Form.Select>
              </InputGroup>
              {errors?.driver_status && (
                <span className="text-danger">
                  {errors?.driver_status?.message}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="ride-status" className="mt-2">
              <Form.Label>Passenger Status</Form.Label>
              <InputGroup className="mb-3">
                <Form.Select {...register("passenger_status")}>
                  <option value="">All Statuses</option>
                  <option value="unverified">Unverified</option>
                  <option value="verified">Verified</option>
                  <option value="pending">Pending</option>
                  <option value="blocked">Blocked</option>
                </Form.Select>
              </InputGroup>
              {errors?.passenger_status && (
                <span className="text-danger">
                  {errors?.passenger_status?.message}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="ride-status" className="mt-2">
              <Form.Label>Profile Status</Form.Label>
              <InputGroup className="mb-3">
                <Form.Select {...register("profile_status")}>
                  <option value="">All Profile Statuses</option>
                  <option value="incomplete">Incomplete</option>
                  <option value="complete">Complete</option>
                </Form.Select>
              </InputGroup>
              {errors?.profile_status && (
                <span className="text-danger">
                  {errors?.profile_status?.message}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="ride-status" className="mt-2">
              <Form.Label>Active Status</Form.Label>
              <InputGroup className="mb-3">
                <Form.Select {...register("active_status")}>
                  <option value="">All Active Statuses</option>
                  <option value="new_users">New Users</option>
                  <option value="weekly_active">Active this Week</option>
                  <option value="monthly_active">Active this month</option>
                  <option value="quarterly_active">Active last 4 months</option>
                  <option value="yearly_active">Active last 12 months</option>
                </Form.Select>
              </InputGroup>
              {errors?.active_status && (
                <span className="text-danger">
                  {errors?.active_status?.message}
                </span>
              )}
            </Form.Group>
            {messageCountData && (
              <Alert variant="primary">
                <h6>Messages that will be sent out:</h6>
                <p className="mb-0">
                  <span className="fw-bold">
                    {messageCountData?.notifications}
                  </span>{" "}
                  Notifications
                </p>
                <p className="mb-0">
                  <span className="fw-bold">{messageCountData?.sms}</span> SMS{" "}
                </p>
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="secondary" onClick={() => onGetMessageCounts()}>
              Get Message Counts
            </Button>
            <Button
              variant="primary"
              type="submit"
              disabled={messageCountData === null ? true : false}
            >
              Send Message
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddMessage;
