import { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addCredits } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { useForm } from "react-hook-form";

const AddCreditsModal = ({ show, setShow, id }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const handleClose = () => {
    reset();
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false);
  };

  //Form initiation
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: {
      wallet_credits: "",
      id: id,
    },
  });

  const onSubmit = (data) => {
    addCreditsMutation.mutate(data);
  };

  // Queries & Mutations
  const queryClient = useQueryClient();

  //Update Credits Status
  const addCreditsMutation = useMutation({
    mutationFn: (data) => addCredits(data),
    onSuccess: (dataResponse) => {
      setErrorMessage("");
      setSuccessMessage("Updated successfully!");
      queryClient.invalidateQueries("user");
    },
    onError(error: any) {
      setSuccessMessage("");
      setErrorMessage(Functions.handleError(error));
    },
  });

  //Effects

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        contentClassName="bg-white"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Credits</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
            {successMessage && (
              <span className="text-primary">{successMessage}</span>
            )}

            <Form.Group controlId="blocked-reason" className="mt-2">
              <Form.Label>Credits Amount</Form.Label>
              <Form.Control
                type="text"
                placeholder="amount in UGX"
                {...register("wallet_credits")}
              />
              {errors?.wallet_credits && (
                <span className="text-danger">
                  {errors?.wallet_credits?.message}
                </span>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddCreditsModal;
