import React, { useState } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import {
  Spinner,
  Badge,
  Image,
  Table,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { getPartnerRides, getPartnerRideStats } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";
import AddModal from "./AddModal";
import numbro from "numbro";

const PartnerRides = () => {
  const history = useHistory();
  //State
  const [showAdd, setShowAdd] = useState(false);
  const [routeType, setRouteType] = useState(null);
  const [rideType, setRideType] = useState(null);
  const [isDelayed, setIsDelayed] = useState(null);
  const [isArchived, setIsArchived] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  //Queries & Mutations
  //Get Partner Rides
  const {
    data,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["partner-rides", routeType, rideType, isArchived, searchQuery],
    queryFn: ({ pageParam = 0 }) =>
      getPartnerRides({
        page: pageParam,
        route_type: routeType,
        ride_type: rideType,
        is_archived: isArchived,
        is_delayed: isDelayed,
        search: searchQuery,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.data.length < 20) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  //Get Stats
  const {
    isLoading: isLoadingStats,
    isError: isErrorStats,
    data: dataStats,
  } = useQuery({
    queryKey: ["partnerRideStats"],
    queryFn: () => getPartnerRideStats(),
  });

  //Functions
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchQuery(searchInput);
  };

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">partner-rides</p>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <p className="h2">Partner Rides</p>
          <Button variant="outline-secondary" onClick={() => setShowAdd(true)}>
            Add New
          </Button>
        </div>
        {isLoadingStats ? null : isErrorStats ? (
          <p className="breadcrumb-text">Error loading stats</p>
        ) : (
          <div className="d-flex flex-row align-items-center mt-2">
            <p className="breadcrumb-text mx-3">
              <span className="fw-bold">All</span> ({dataStats?.total_rides})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setRouteType("standard")}
            >
              <span className="fw-bold text-primary">Standard</span> (
              {dataStats?.standard_rides})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setRouteType("custom")}
            >
              <span className="fw-bold text-primary">Custom</span> (
              {dataStats?.custom_rides})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setRideType("economy")}
            >
              <span className="fw-bold text-primary">Economy</span> (
              {dataStats?.economy_rides})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setRideType("business")}
            >
              <span className="fw-bold text-primary">Business</span> (
              {dataStats?.business_rides})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setRideType("vip")}
            >
              <span className="fw-bold text-primary">VIP</span> (
              {dataStats?.vip_rides})
            </p>

            <p
              className="breadcrumb-text mx-3"
              onClick={() => setIsDelayed("f")}
            >
              <span className="fw-bold text-primary">Ongoing</span> (
              {dataStats?.ongoing_rides})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setIsDelayed("t")}
            >
              <span className="fw-bold text-primary">Delayed</span> (
              {dataStats?.delayed_rides})
            </p>
          </div>
        )}
        <Form className="mb-3" onSubmit={handleSearchSubmit}>
          <Row>
            <Col md={4}>
              <InputGroup size="sm">
                <FormControl
                  type="search"
                  placeholder="Search by Origin, Destination, Partner, Locations"
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button variant="secondary" type="submit">
                  Search
                </Button>
              </InputGroup>
            </Col>
            <Col md={6} className="mt-2 mt-md-0">
              <InputGroup className="mb-3">
                <Form.Select
                  size="sm"
                  value={routeType}
                  onChange={(e) => setRouteType(e.target.value)}
                >
                  <option value="">Route Type</option>
                  <option value="standard">Standard</option>
                  <option value="custom">Custom</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={rideType}
                  onChange={(e) => setRideType(e.target.value)}
                >
                  <option value="">Ride Type</option>
                  <option value="economy">Economy</option>
                  <option value="business">Business</option>
                  <option value="vip">VIP</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={isDelayed}
                  onChange={(e) => setIsDelayed(e.target.value)}
                >
                  <option value="">Is Delayed</option>
                  <option value="t">True</option>
                  <option value="f">False</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={isArchived}
                  onChange={(e) => setIsArchived(e.target.value)}
                >
                  <option value="">Is Archived</option>
                  <option value="t">True</option>
                  <option value="f">False</option>
                </Form.Select>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    setIsArchived("");
                    setRouteType("");
                    setRideType("");
                    setIsDelayed("");
                    setSearchInput("");
                    setSearchQuery("");
                  }}
                >
                  Clear Filters
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
      {status === "error" ? (
        <p className="text-center"> {Functions.handleError(error)}</p>
      ) : status === "pending" ? null : (
        <div className="px-md-5 px-2 mt-md-2 mt-1 bg-white table-header">
          <Table responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Partner</th>
                <th>Route</th>
                <th>Agent Contacts</th>
                <th>Amount</th>
                <th>Boarding Info</th>
                <th>Route Type</th>
                <th>Ride Type</th>
                <th>Repeat</th>
                <th>Departure</th>
                <th>Status</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {data.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.data.map((item) => (
                    <tr
                      key={item?.id}
                      onClick={() =>
                        history.push(`/dashboard/partner-rides/${item?.id}`)
                      }
                    >
                      <td className="table-text">{item?.id}</td>
                      <td className="table-text">
                        {item?.partnerDetail?.company_name}
                      </td>
                      <td className="table-text">
                        <p className="table-text">
                          <span className="fw-bold">
                            {item?.originDetail.name}
                          </span>{" "}
                          {" > "}{" "}
                          <span className="fw-bold">
                            {item?.destinationDetail.name}
                          </span>
                        </p>
                      </td>
                      <td className="table-text">
                        <p className="text-secondary table-text mb-0">
                          {item?.partnerLocationDetail?.name}
                        </p>
                        {item?.partnerLocationDetail?.contacts?.length > 0 &&
                          item?.partnerLocationDetail?.contacts?.map(
                            (item, index) => {
                              return (
                                <Badge bg="secondary" className="mt-2 me-2">
                                  {item?.title} {item?.name} ({item?.phone})
                                </Badge>
                              );
                            }
                          )}
                      </td>
                      <td className="table-text">
                        <p className="text-secondary table-text mb-0">
                          {" "}
                          {numbro(item?.amount).format({
                            thousandSeparated: true,
                          })}{" "}
                          UGX
                        </p>
                      </td>
                      <td className="table-text">
                        <p className="text-secondary table-text mb-0">
                          {item?.boarding_info}
                        </p>
                      </td>
                      <td className="table-text">{item?.route_type}</td>
                      <td className="table-text">{item?.ride_type}</td>
                      <td className="table-text">
                        <Badge bg="secondary">{item?.repeat}</Badge>
                      </td>
                      <td className="table-text">
                        {item?.departure?.length > 0 &&
                          item?.departure?.map((item, index) => {
                            return (
                              <Badge bg="secondary" className="me-2">
                                {item?.time}{" "}
                                {item?.day?.length > 0 && `- ${item?.day}`}
                              </Badge>
                            );
                          })}
                      </td>
                      <td className="table-text">
                        <Badge
                          bg={
                            item?.status === "active" ? "primary" : "secondary"
                          }
                        >
                          {item?.status}
                        </Badge>
                      </td>
                      <td className="table-text text-secondary">
                        {DateTime.fromISO(item?.created_at).toLocaleString(
                          DateTime.DATE_MED
                        )}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <div className="py-2 d-flex flex-row justify-content-center">
        <Button
          variant="outline-secondary"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "No More Results"}
        </Button>
      </div>
      <div className="py-2 d-flex flex-row justify-content-center">
        {(isFetching || isLoadingStats || status === "pending") &&
        !isFetchingNextPage ? (
          <Spinner animation="border" />
        ) : null}
      </div>
      <AddModal setShow={setShowAdd} show={showAdd} />
    </>
  );
};

export default PartnerRides;
