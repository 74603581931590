import React, { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import {
  Spinner,
  Badge,
  Table,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import { getMessages } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";
import AddMessage from "./AddMessage";
import AddMessageSingle from "./AddMessageSingle";

const Messages = () => {
  const history = useHistory();
  //State
  const [driverStatus, setDriverStatus] = useState(null);
  const [profileStatus, setProfileStatus] = useState(null);
  const [isCustom, setIsCustom] = useState(null);
  const [passengerStatus, setPassengerStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [showAM, setShowAM] = useState(false);
  const [showAMS, setShowAMS] = useState(false);

  //Queries & Mutations
  //Get Messages
  const {
    data,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: [
      "messages",
      driverStatus,
      passengerStatus,
      profileStatus,
      searchQuery,
      isCustom,
    ],
    queryFn: ({ pageParam = 0 }) =>
      getMessages({
        page: pageParam,
        driver_status: driverStatus,
        passenger_status: passengerStatus,
        profile_status: profileStatus,
        search: searchQuery,
        custom: isCustom,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.data.length < 20) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  //Functions
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchQuery(searchInput);
  };

  return (
    <>
      <AddMessage setShow={setShowAM} show={showAM} />
      <AddMessageSingle setShow={setShowAMS} show={showAMS} />
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">Messages</p>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <p className="h2">Messages</p>
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-white">
              <Dropdown.Item onClick={() => setShowAM(true)}>
                Send Bulk Message
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowAMS(true)}>
                Send Single Message
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <Form className="mb-3 mt-3" onSubmit={handleSearchSubmit}>
          <Row>
            <Col md={4}>
              <InputGroup size="sm">
                <FormControl
                  type="search"
                  placeholder="Search message contents"
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button variant="secondary" type="submit">
                  Search
                </Button>
              </InputGroup>
            </Col>
            <Col md={8} className="mt-2 mt-md-0">
              <InputGroup className="mb-3">
                <Form.Select
                  size="sm"
                  value={driverStatus}
                  onChange={(e) => setDriverStatus(e.target.value)}
                >
                  <option value="">Driver Status</option>
                  <option value="verified">Verified</option>
                  <option value="pending">Pending</option>
                  <option value="unverified">Unverified</option>
                  <option value="blocked">Blocked</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={passengerStatus}
                  onChange={(e) => setPassengerStatus(e.target.value)}
                >
                  <option value="">Passenger Status</option>
                  <option value="verified">Verified</option>
                  <option value="pending">Pending</option>
                  <option value="unverified">Unverified</option>
                  <option value="blocked">Blocked</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={profileStatus}
                  onChange={(e) => setProfileStatus(e.target.value)}
                >
                  <option value="">Profile Status</option>
                  <option value="incomplete">Incomplete</option>
                  <option value="complete">Complete</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={isCustom}
                  onChange={(e) => setIsCustom(e.target.value)}
                >
                  <option value="">Is Custom</option>
                  <option value="t">True</option>
                  <option value="f">False</option>
                </Form.Select>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    setPassengerStatus("");
                    setDriverStatus("");
                    setProfileStatus("");
                    setSearchInput("");
                    setSearchQuery("");
                  }}
                >
                  Clear Filters
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
      {status === "error" ? (
        <p className="text-center"> {Functions.handleError(error)}</p>
      ) : status === "pending" ? null : (
        <div className="px-md-5 px-2 mt-md-2 mt-1 bg-white table-header">
          <Table responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Message</th>
                <th>Message Type</th>
                <th>SMS Sent</th>
                <th>Notification Sent</th>
                <th>User IDs</th>
                <th>Message Params</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {data.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.data.map((item) => (
                    <tr
                      key={item?.id}
                      onClick={() =>
                        history.push(`/dashboard/messages/${item?.id}`)
                      }
                    >
                      <td className="table-text">{item?.id}</td>
                      <td className="table-text">{item?.message}</td>
                      <td className="table-text">
                        {item?.send_notification && (
                          <Badge bg="primary" className="me-1">
                            Notification
                          </Badge>
                        )}
                        {item?.send_sms && (
                          <Badge bg="primary" className="me-1">
                            SMS
                          </Badge>
                        )}
                      </td>
                      <td className="table-text">{item?.sms_count}</td>
                      <td className="table-text">
                        {item?.notifications_count}
                      </td>
                      <td className="table-text">
                        {item?.ids &&
                          item?.ids?.map((userId, index) => {
                            return (
                              <Badge bg="secondary" className="me-2">
                                {userId}
                              </Badge>
                            );
                          })}
                      </td>
                      <td className="table-text">
                        {item?.driver_status && (
                          <Badge bg="secondary" className="me-2">
                            Driver Status: {item?.driver_status}
                          </Badge>
                        )}
                        {item?.passenger_status && (
                          <Badge bg="secondary" className="me-2">
                            Passenger Status: {item?.passenger_status}
                          </Badge>
                        )}
                        {item?.profile_status && (
                          <Badge bg="secondary" className="me-2">
                            Profile Status: {item?.profile_status}
                          </Badge>
                        )}

                        {item?.active_status && (
                          <Badge bg="secondary" className="me-2">
                            Active Status: {item?.active_status}
                          </Badge>
                        )}
                        {item?.custom && (
                          <Badge bg="danger" className="me-2">
                            Custom Message
                          </Badge>
                        )}
                      </td>
                      <td className="table-text text-secondary">
                        {DateTime.fromISO(item?.created_at).toLocaleString(
                          DateTime.DATE_MED
                        )}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <div className="py-2 d-flex flex-row justify-content-center">
        <Button
          variant="outline-secondary"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "No More Results"}
        </Button>
      </div>
      <div className="py-2 d-flex flex-row justify-content-center">
        {(isFetching || status === "pending") && !isFetchingNextPage ? (
          <Spinner animation="border" />
        ) : null}
      </div>
    </>
  );
};

export default Messages;
