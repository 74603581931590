import React, { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { useHistory } from "react-router-dom";

const Logout = () => {
  const { setToken } = useContext(AuthContext);
  const history = useHistory();

  useEffect(() => {
    setToken(null);

    // Remove the token from local storage
    localStorage.removeItem("token");
    history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>Logging out...</div>;
};

export default Logout;
