import { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deletePartnerLocation } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { useForm } from "react-hook-form";

const DeleteLocationModal = ({ show, setShow, itemData }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleClose = () => {
    reset();
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false);
  };

  //Form initiation
  const {
    handleSubmit,
    reset,
    // eslint-disable-next-line no-unused-vars
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    defaultValues: {
      id: "",
    },
  });

  useEffect(() => {
    if (show) {
      reset({
        id: itemData?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = (data) => {
    deleteMutation.mutate(data);
  };

  // Queries & Mutations
  const queryClient = useQueryClient();

  //Update Status
  const deleteMutation = useMutation({
    mutationFn: (data) => deletePartnerLocation(data),
    onSuccess: (dataResponse) => {
      setErrorMessage("");
      setSuccessMessage("Partner Location deleted successfully.");
      queryClient.invalidateQueries("partners");
    },
    onError(error: any) {
      setSuccessMessage("");
      setErrorMessage(Functions.handleError(error));
    },
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        contentClassName="bg-white"
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete Partner Location</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
            {successMessage && (
              <span className="text-primary">{successMessage}</span>
            )}
            <p>Confirm deleting location.</p>
            <p>Location: {itemData?.name}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="danger" type="submit">
              Delete Partner
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default DeleteLocationModal;
