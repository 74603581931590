import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Spinner,
  Badge,
  Image,
  Table,
  Row,
  Col,
  Card,
  Tab,
  Nav,
  Dropdown,
} from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { getRide } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";
import numbro from "numbro";
import RideStatusModal from "./RideStatusModal";

const Ride = () => {
  let { id } = useParams();

  //State
  const [showRS, setShowRS] = useState(false);

  // Queries & Mutations
  //Ger User
  const { isLoading, error, isError, data } = useQuery({
    queryKey: ["ride", id],
    queryFn: () => getRide({ id: id }),
  });

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (isError) {
    return <p className="text-center"> {Functions.handleError(error)}</p>;
  }

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <LinkContainer to={"/dashboard/rides"}>
            <p className="text-primary breadcrumb-text fw-bold">Rides</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">{id}</p>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <p className="h2">
              {data?.originDetail?.name} {">"} {data?.destinationDetail?.name}
            </p>
            <p className="lh-sm my-0 py-0">
              {DateTime.fromISO(data?.departure).toLocaleString(
                DateTime.DATETIME_MED
              )}
            </p>
            <p className="lh-sm my-0 py-0">{data?.pickup}</p>
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-white">
              <Dropdown.Item onClick={() => setShowRS(true)}>
                Update Ride Status
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Row className="px-md-5 px-2">
        <Col md="8">
          <Card bg="white">
            <Card.Body>
              <div className="d-flex flex-row align-items-center border-bottom border-dashed pb-4">
                {data?.ownerDetail?.picture !== null ? (
                  <Image
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      data?.ownerDetail?.picture
                    }
                    roundedCircle
                    width="150"
                    height="150"
                    className="mr-3"
                  />
                ) : null}
                <div className="ms-3">
                  <h3>{data?.ownerDetail?.name}</h3>
                  <p className="mb-0">{data?.ownerDetail?.phone}</p>
                  <p className="mb-0">{data?.ownerDetail?.gender}</p>
                  <p className="mb-0">
                    Rating{" "}
                    {numbro(data?.ownerDetail?.average_rating).format({
                      mantissa: 1,
                    })}{" "}
                    ({data?.ownerDetail?.rating_count})
                  </p>
                </div>
              </div>
              <div className="d-flex flex-row justify-content-between pt-4">
                <div>
                  <p className="breadcrumb-text fw-bold lh-sm my-1 py-0">
                    Ride Created
                  </p>
                  <p className="lh-sm my-0 py-0">
                    {DateTime.fromISO(data?.created_at).toLocaleString(
                      DateTime.DATETIME_MED
                    )}
                  </p>
                </div>
                <div>
                  <p className="breadcrumb-text fw-bold lh-sm my-1 py-0">
                    Expiry Date
                  </p>
                  <p className="lh-sm my-0 py-0">
                    {DateTime.fromISO(data?.expiry_date).toLocaleString(
                      DateTime.DATETIME_MED
                    )}
                  </p>
                </div>
                <div>
                  <p className="breadcrumb-text fw-bold lh-sm my-1 py-0">
                    Last Update
                  </p>
                  {data?.updated_at !== null ? (
                    <p className="lh-sm my-0 py-0">
                      {DateTime.fromISO(data?.updated_at).toLocaleString(
                        DateTime.DATETIME_MED
                      )}
                    </p>
                  ) : (
                    <p className="lh-sm my-0 py-0">No updates</p>
                  )}
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mt-2 mt-md-0">
          <Card className="mb-3" bg="white">
            <Card.Body>
              <h4>Ride Particulars</h4>
              <div className="border-bottom border-dashed pb-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Status</p>
                  <Badge
                    bg={
                      data?.status === "completed"
                        ? "primary"
                        : data?.status === "cancelled"
                        ? "danger"
                        : "secondary"
                    }
                  >
                    {data?.status}
                  </Badge>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Seats</p>
                  <p>{data?.passengers}</p>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Cost per Seat</p>
                  <p>
                    {" "}
                    {numbro(data?.amount).format({
                      thousandSeparated: true,
                    })}{" "}
                    UGX
                  </p>
                </div>
              </div>
              <div className="pt-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Is Archived</p>
                  <Badge bg={data?.is_archived ? "primary" : "secondary"}>
                    {data?.is_archived ? "True" : "False"}
                  </Badge>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mx-5">
        <Col>
          <Tab.Container id="left-tabs-example" defaultActiveKey="bookings">
            <Nav variant="underline" className="pb-3 custom-nav">
              <Nav.Item>
                <Nav.Link eventKey="bookings">Bookings</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="payments">Trip Payments</Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="payments" title="Payments">
                <p>Payments go here...fetch</p>
              </Tab.Pane>
              <Tab.Pane eventKey="bookings" title="Bookings">
                {data?.rideBookings?.length > 0 ? (
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Booked By</th>
                        <th>Seats</th>
                        <th>Amount</th>
                        <th>Status</th>
                        <th>Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.rideBookings?.map((item, index) => {
                        return (
                          <tr key={item?.id}>
                            <td className="table-text">{item?.id}</td>
                            <td className="table-text text-secondary d-flex flex-row align-items-center">
                              {item?.owner?.picture !== null ? (
                                <div>
                                  <Image
                                    src={
                                      process.env.REACT_APP_IMAGE_URL +
                                      item?.owner?.picture
                                    }
                                    roundedCircle
                                    width="50"
                                    height="50"
                                    className="mr-3"
                                  />
                                </div>
                              ) : null}
                              <div className="ms-2">
                                <p className="text-secondary table-text mb-0">
                                  {item?.owner?.name}
                                </p>
                                <p className="text-secondary breadcrumb-text mb-0">
                                  {item?.owner?.phone}
                                </p>
                                <p className="text-secondary breadcrumb-text mb-0">
                                  {item?.owner?.gender} |{" "}
                                  {item?.owner?.average_rating} (
                                  {item?.owner?.rating_count})
                                </p>
                              </div>
                            </td>
                            <td className="table-text">{item?.seats}</td>
                            <td className="text-secondary table-text">
                              {numbro(item?.amount).format({
                                thousandSeparated: true,
                              })}{" "}
                              UGX
                            </td>
                            <td>
                              <Badge
                                bg={
                                  item?.status === "accepted"
                                    ? "primary"
                                    : item?.status === "cancelled"
                                    ? "danger"
                                    : "secondary"
                                }
                              >
                                {data?.status}
                              </Badge>
                            </td>
                            <td className="table-text">
                              {" "}
                              {DateTime.fromISO(
                                item?.created_at
                              ).toLocaleString(DateTime.DATETIME_MED)}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <p>No bookings found.</p>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </Col>
      </Row>
      <RideStatusModal setShow={setShowRS} show={showRS} id={id} />
    </>
  );
};

export default Ride;
