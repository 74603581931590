import { useState } from "react";
import {
  Form,
  Button,
  Modal,
  Alert,
  FormControl,
  ListGroup,
  Spinner,
  Badge,
  Card,
} from "react-bootstrap";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { refundTransaction, userSearch } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { useForm } from "react-hook-form";

const AddRefund = ({ show, setShow }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState(null);

  const handleClose = () => {
    reset();
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false);
  };

  //Form initiation
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: {
      user: null,
      amount: "",
      transaction_fees: "",
      account_number: "",
    },
  });

  const watchUser = watch("user", null);

  const onSubmit = (data) => {
    let params = {
      id: data?.user?.id,
      amount: data?.amount,
      transaction_fees: data?.transaction_fees,
      account_number: data?.account_number,
    };
    addRefundMutation.mutate(params);
  };

  // Queries & Mutations
  const queryClient = useQueryClient();

  //User Search
  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    error: errorUsers,
    data: dataUsers,
  } = useQuery({
    queryKey: ["searched-users", searchQuery],
    queryFn: async () => await userSearch({ search: searchQuery }),
  });

  //Update Status
  const addRefundMutation = useMutation({
    mutationFn: (data) => refundTransaction(data),
    onSuccess: (dataResponse) => {
      setErrorMessage("");
      setSuccessMessage("Updated successfully!");
      queryClient.invalidateQueries("transactions");
    },
    onError(error: any) {
      setSuccessMessage("");
      setErrorMessage(Functions.handleError(error));
    },
  });

  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        contentClassName="bg-white"
      >
        <Modal.Header closeButton>
          <Modal.Title>Refund Transaction</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
            {successMessage && (
              <span className="text-primary">{successMessage}</span>
            )}

            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                type="text"
                placeholder="amount"
                {...register("amount")}
              />
              {errors?.amount && (
                <span className="text-danger">{errors?.amount?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="transaction-fees" className="mt-2">
              <Form.Label>Transaction Fees</Form.Label>
              <Form.Control
                type="text"
                placeholder="transaction fees"
                {...register("transaction_fees")}
              />
              {errors?.transaction_fees && (
                <span className="text-danger">
                  {errors?.transaction_fees?.message}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Phone Number Sent To</Form.Label>
              <Form.Control
                type="text"
                placeholder="phone number"
                {...register("account_number")}
              />
              {errors?.account_number && (
                <span className="text-danger">
                  {errors?.account_number?.message}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="message" className="mt-2">
              <Form.Label>Select User</Form.Label>
              {watchUser !== null && (
                <Alert variant="primary">
                  {watchUser?.name} | {watchUser?.phone} |{" "}
                  {watchUser?.notification_token !== null ? (
                    <Badge variant="primary">notifications-enabled</Badge>
                  ) : (
                    <Badge variant="secondary">notifications-disabled</Badge>
                  )}
                </Alert>
              )}

              <FormControl
                type="search"
                placeholder="Search by id, name or phone"
                aria-label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Card className="mt-2">
                {isLoadingUsers ? (
                  <Spinner animation="border" />
                ) : isErrorUsers ? (
                  <p>{Functions.handleError(errorUsers)}</p>
                ) : (
                  <ListGroup variant="flush">
                    {dataUsers.map((item, index) => {
                      return (
                        <ListGroup.Item
                          key={item.id}
                          onClick={() => setValue("user", item)}
                        >
                          {item?.name} | {item?.phone} |{" "}
                          {item?.notification_token !== null ? (
                            <Badge variant="primary">
                              notifications-enabled
                            </Badge>
                          ) : (
                            <Badge variant="secondary">
                              notifications-disabled
                            </Badge>
                          )}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                )}
              </Card>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Add Refund
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddRefund;
