import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Spinner,
  Badge,
  Image,
  Row,
  Col,
  Card,
  Dropdown,
} from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { getPartnerRide } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";
import numbro from "numbro";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";

const PartnerRide = () => {
  let { id } = useParams();

  //State
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  // Queries & Mutations
  //Ger User
  const { isLoading, error, isError, data } = useQuery({
    queryKey: ["partner-ride", id],
    queryFn: () => getPartnerRide({ id: id }),
  });

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (isError) {
    return <p className="text-center"> {Functions.handleError(error)}</p>;
  }

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <LinkContainer to={"/dashboard/partner-rides"}>
            <p className="text-primary breadcrumb-text fw-bold">
              partner-rides
            </p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">{id}</p>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <p className="h2">
              {data?.originDetail?.name} {">"} {data?.destinationDetail?.name}
            </p>
            <p className="lh-sm my-0 py-0">{data?.pickup}</p>
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-white">
              <Dropdown.Item onClick={() => setShowEdit(true)}>
                Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowDelete(true)}>
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Row className="px-md-5 px-2">
        <Col md="8">
          <Card className="mb-3" bg="white">
            <Card.Body>
              <h4>Ride Particulars</h4>
              <div className="border-bottom border-dashed pb-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Route Type</p>
                  <Badge bg="secondary">{data?.route_type}</Badge>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Ride Type</p>
                  <Badge bg="secondary">{data?.ride_type}</Badge>
                </div>
              </div>
              <div className="pt-2 border-bottom border-dashed pb-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Repeat</p>
                  <Badge bg="primary">{data?.repeat}</Badge>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Departure Times</p>
                  <div>
                    {data?.departure?.length > 0 &&
                      data?.departure?.map((item, index) => {
                        return (
                          <Badge bg="primary" className="me-1">
                            {item?.time}{" "}
                            {item?.day?.length > 0 && `- ${item?.day}`}
                          </Badge>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="pt-2 border-bottom border-dashed pb-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Seats</p>
                  <p>{data?.seats}</p>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Cost per Seat</p>
                  <p>
                    {" "}
                    {numbro(data?.amount).format({
                      thousandSeparated: true,
                    })}{" "}
                    UGX
                  </p>
                </div>
              </div>
              <div className="pt-2 border-bottom border-dashed pb-2">
                <div>
                  <p>Boarding Info</p>
                  <p>{data?.boarding_info}</p>
                </div>
              </div>
              <div className="pt-2 border-bottom border-dashed pb-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Ride Status</p>
                  <Badge
                    bg={data?.status === "active" ? "primary" : "secondary"}
                  >
                    {data?.status}
                  </Badge>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Is Delayed</p>
                  <Badge bg={data?.is_delayed ? "primary" : "secondary"}>
                    {data?.is_delayed ? "True" : "False"}
                  </Badge>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Delay Reason</p>
                  <p>{data?.delayed_reason}</p>
                </div>
              </div>
              <div className="pt-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Is Archived</p>
                  <Badge bg={data?.is_archived ? "primary" : "secondary"}>
                    {data?.is_archived ? "True" : "False"}
                  </Badge>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col className="mt-2 mt-md-0">
          <Card bg="white">
            <Card.Body>
              <div className="d-flex flex-row align-items-center pb-4">
                {data?.partnerDetail?.picture !== null ? (
                  <Image
                    src={
                      process.env.REACT_APP_IMAGE_URL +
                      data?.partnerDetail?.picture
                    }
                    roundedCircle
                    width="150"
                    height="150"
                    className="mr-3"
                  />
                ) : null}
                <div className="ms-3">
                  <h3>{data?.partnerDetail?.company_name}</h3>
                  <p className="mb-0">{data?.partnerDetail?.email}</p>
                  <p className="mb-0">
                    Address: {data?.partnerDetail?.address}
                  </p>

                  <p className="mb-0">Notes: {data?.partnerDetail?.notes}</p>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card bg="white" className="mt-2">
            <Card.Body>
              <div className="d-flex flex-row align-items-center pb-4">
                <div className="ms-3">
                  <h3>{data?.partnerLocationDetail?.name}</h3>
                  <p className="mb-0">Contacts</p>
                  <ul>
                    {data?.partnerLocationDetail?.contacts?.length > 0 &&
                      data?.partnerLocationDetail?.contacts?.map(
                        (item, index) => {
                          return (
                            <li>
                              {item?.title} {item?.name} ({item?.phone})
                            </li>
                          );
                        }
                      )}
                  </ul>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <EditModal setShow={setShowEdit} show={showEdit} itemData={data} />
      <DeleteModal setShow={setShowDelete} show={showDelete} itemData={data} />
    </>
  );
};

export default PartnerRide;
