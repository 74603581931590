import { useState, useEffect } from "react";
import { Form, Button, InputGroup, Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editPartner } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { useForm } from "react-hook-form";
//import ImageUpload from "../../components/ImageUpload";

const AddModal = ({ show, setShow, itemData }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleClose = () => {
    reset();
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false);
  };

  //Form initiation
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: {
      company_name: "",
      email: "",
      address: "",
      type: "",
      status: "",
      notes: "",
    },
  });

  useEffect(() => {
    if (show) {
      reset({
        company_name: itemData?.company_name,
        email: itemData?.email,
        address: itemData?.address,
        type: itemData?.type,
        status: itemData?.status,
        notes: itemData?.notes,
        id: itemData?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = (data) => {
    editMutation.mutate(data);
  };

  // Queries & Mutations
  const queryClient = useQueryClient();

  //Update Status
  const editMutation = useMutation({
    mutationFn: (data) => editPartner(data),
    onSuccess: (dataResponse) => {
      setErrorMessage("");
      setSuccessMessage("Partner edited successfully.");
      queryClient.invalidateQueries("partners");
    },
    onError(error: any) {
      setSuccessMessage("");
      setErrorMessage(Functions.handleError(error));
    },
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        contentClassName="bg-white"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Partner</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
            {successMessage && (
              <span className="text-primary">{successMessage}</span>
            )}
            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Company Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="company name"
                {...register("company_name")}
              />
              {errors?.company_name && (
                <span className="text-danger">
                  {errors?.company_name?.message}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                placeholder="email"
                {...register("email")}
              />
              {errors?.email && (
                <span className="text-danger">{errors?.email?.message}</span>
              )}
            </Form.Group>
            {/* <ImageUpload /> */}
            {/* <Form.Group controlId="name" className="mt-2">
              <Form.Label>Picture</Form.Label>
              <Form.Control
                type="file"
                placeholder="picture"
                {...register("picture")}
              />
              {errors?.email && (
                <span className="text-danger">{errors?.picture?.message}</span>
              )}
            </Form.Group> */}
            <Form.Group controlId="status" className="mt-2">
              <Form.Label>Status</Form.Label>
              <InputGroup className="mb-3">
                <Form.Select
                  {...register("status", {
                    required: "Status is required",
                  })}
                >
                  <option value="">Status</option>
                  <option value="pending">Pending</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Form.Select>
              </InputGroup>
              {errors?.status && (
                <span className="text-danger">{errors?.status?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="type" className="mt-2">
              <Form.Label>Partner Type</Form.Label>
              <InputGroup className="mb-3">
                <Form.Select
                  {...register("type", {
                    required: "Type is required",
                  })}
                >
                  <option value="">Type</option>
                  <option value="bus">Bus</option>
                  <option value="car">Car</option>
                  <option value="airline">Airline</option>
                </Form.Select>
              </InputGroup>
              {errors?.type && (
                <span className="text-danger">{errors?.type?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="address" className="mt-2">
              <Form.Label>Address</Form.Label>
              <Form.Control
                as="textarea"
                {...register("address", {
                  required: "Address is required",
                })}
                rows={3}
                placeholder="Type partner physical address here..."
              />
              {errors?.address && (
                <span className="text-danger">{errors?.address?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="notes" className="mt-2">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                {...register("notes")}
                rows={3}
                placeholder="Any special notes on partner ..."
              />
              {errors?.address && (
                <span className="text-danger">{errors?.notes?.message}</span>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Edit Partner
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddModal;
