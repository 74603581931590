import React, { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  Spinner,
  Badge,
  Table,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import { getPartners } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";
import { useHistory } from "react-router-dom";

const Partners = () => {
  const history = useHistory();
  //State
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [showAdd, setShowAdd] = useState(false);

  //Queries & Mutations
  //Get Partners
  const {
    data,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["partners", searchQuery],
    queryFn: ({ pageParam = 0 }) =>
      getPartners({
        page: pageParam,
        search: searchQuery,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.data.length < 20) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  //Functions
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchQuery(searchInput);
  };

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">Partners</p>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <p className="h2">Partners</p>
          <Button variant="outline-secondary" onClick={() => setShowAdd(true)}>
            Add New
          </Button>
        </div>
        <Form className="mb-3" onSubmit={handleSearchSubmit}>
          <Row>
            <Col md={4}>
              <InputGroup size="sm">
                <FormControl
                  type="search"
                  placeholder="Search by company name"
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button variant="secondary" type="submit">
                  Search
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
      {status === "error" ? (
        <p className="text-center"> {Functions.handleError(error)}</p>
      ) : status === "pending" ? null : (
        <div className="px-md-5 px-2 mt-md-2 mt-1 bg-white table-header">
          <Table responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Picture</th>
                <th>Company Name</th>
                <th>Email</th>
                <th>Address</th>
                <th>Type</th>
                <th>Notes</th>
                <th>Status</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Is Archived</th>
                <th>Archived At</th>
              </tr>
            </thead>
            <tbody>
              {data.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.data.map((item) => (
                    <tr
                      key={item.id}
                      onClick={() =>
                        history.push(`/dashboard/partners/${item?.id}`)
                      }
                    >
                      <td className="table-text">{item.id}</td>
                      <td className="table-text text-secondary">
                        {item?.picture}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.company_name}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.email}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.address}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.type}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.notes}
                      </td>
                      <td className="table-text">
                        <Badge bg="secondary" className="mt-2">
                          {item?.status}
                        </Badge>
                      </td>
                      <td className="table-text text-secondary">
                        {DateTime.fromISO(item?.created_at).toLocaleString(
                          DateTime.DATE_MED
                        )}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.updated_at !== null
                          ? DateTime.fromISO(item?.updated_at).toLocaleString(
                              DateTime.DATE_MED
                            )
                          : "No update"}
                      </td>
                      <td className="table-text text-secondary">
                        <Badge bg="secondary" className="mt-2">
                          {item?.is_archived ? "TRUE" : "FALSE"}
                        </Badge>
                      </td>
                      <td className="table-text text-secondary">
                        {item?.archived_at !== null
                          ? DateTime.fromISO(item?.archived_at).toLocaleString(
                              DateTime.DATE_MED
                            )
                          : null}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <div className="py-2 d-flex flex-row justify-content-center">
        <Button
          variant="outline-secondary"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "No More Results"}
        </Button>
      </div>
      <div className="py-2 d-flex flex-row justify-content-center">
        {(isFetching || status === "pending") && !isFetchingNextPage ? (
          <Spinner animation="border" />
        ) : null}
      </div>
      <AddModal setShow={setShowAdd} show={showAdd} />
    </>
  );
};

export default Partners;
