//Libraries
import { useContext, useState } from "react";
import { Container, Row, Col, Nav, Navbar } from "react-bootstrap";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import {
  People,
  CarFront,
  Bag,
  ChatDots,
  Geo,
  ShieldShaded,
  Wallet2,
  Briefcase,
  BusFront,
  Cart,
} from "react-bootstrap-icons";

//Components
import { AuthContext } from "../../context/AuthContext";
import Header from "../../components/Header";

//Screens
import Home from "./Home";
import Admins from "../admins/Admins";
import Users from "../users/Users";
import User from "../users/User";
import Rides from "../rides/Rides";
import Ride from "../rides/Ride";
import Booking from "../bookings/Booking";
import Bookings from "../bookings/Bookings";
import Message from "../messaging/Message";
import Messages from "../messaging/Messages";
import Locations from "../locations/Locations";
import Transactions from "../transactions/Transactions";
import Partner from "../partners/Partner";
import Partners from "../partners/Partners";
import PartnerRide from "../partner-rides/PartnerRide";
import PartnerRides from "../partner-rides/PartnerRides";
import Request from "../requests/Request";
import Requests from "../requests/Requests";

function Dashboard() {
  const { token, loading } = useContext(AuthContext);
  let { path, url } = useRouteMatch();
  const [open, setOpen] = useState(false);

  if (loading) {
    return null;
  }

  if (!token) {
    return <Redirect to="/" replace />;
  }

  return (
    <>
      <Header />
      <Container fluid style={{ paddingTop: "60px" }}>
        <Row>
          <Col md={3} lg={2} className="sidebar d-md-block">
            <div className="position-sticky pt-3">
              <Navbar bg="white" expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="flex-column custom-nav" activeKey="/home">
                    <p className="menu-text mb-0 mt-1 ms-3 fw-bold">CARPOOL</p>

                    <LinkContainer to={`${url}/rides`}>
                      <Nav.Link className="text-secondary d-flex flex-row align-items-center">
                        <CarFront />{" "}
                        <span className="menu-text ms-2">Rides</span>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={`${url}/bookings`}>
                      <Nav.Link className="text-secondary d-flex flex-row align-items-center">
                        <Bag />
                        <span className="menu-text ms-2">Bookings</span>
                      </Nav.Link>
                    </LinkContainer>

                    <p className="menu-text mb-0 mt-1 ms-3 fw-bold">PARTNERS</p>
                    <LinkContainer to={`${url}/partners`}>
                      <Nav.Link className="text-secondary d-flex flex-row align-items-center">
                        <Briefcase />
                        <span className="menu-text ms-2">Partners</span>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={`${url}/partner-rides`}>
                      <Nav.Link className="text-secondary d-flex flex-row align-items-center">
                        <BusFront />
                        <span className="menu-text ms-2">Partner Rides</span>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={`${url}/requests`}>
                      <Nav.Link className="text-secondary d-flex flex-row align-items-center">
                        <Cart />
                        <span className="menu-text ms-2">Requests</span>
                      </Nav.Link>
                    </LinkContainer>
                    <p className="menu-text mb-0 mt-1 ms-3 fw-bold">USERS</p>
                    <LinkContainer to={`${url}/users`}>
                      <Nav.Link className="text-secondary d-flex flex-row align-items-center">
                        <People /> <span className="menu-text ms-2">Users</span>
                      </Nav.Link>
                    </LinkContainer>

                    <LinkContainer to={`${url}/admins`}>
                      <Nav.Link className="text-secondary d-flex flex-row align-items-center">
                        <ShieldShaded />
                        <span className="menu-text ms-2">Admins</span>
                      </Nav.Link>
                    </LinkContainer>
                    <p className="menu-text mb-0 mt-1 ms-3 fw-bold">SETTINGS</p>
                    <LinkContainer to={`${url}/transactions`}>
                      <Nav.Link className="text-secondary d-flex flex-row align-items-center">
                        <Wallet2 />
                        <span className="menu-text ms-2">Transactions</span>
                      </Nav.Link>
                    </LinkContainer>

                    <LinkContainer to={`${url}/messages`}>
                      <Nav.Link className="text-secondary d-flex flex-row align-items-center">
                        <ChatDots />
                        <span className="menu-text ms-2">Messages</span>
                      </Nav.Link>
                    </LinkContainer>
                    <LinkContainer to={`${url}/locations`}>
                      <Nav.Link className="text-secondary d-flex flex-row align-items-center">
                        <Geo />
                        <span className="menu-text ms-2">Locations</span>
                      </Nav.Link>
                    </LinkContainer>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </div>
          </Col>
          <Col
            md={{ span: 9, offset: 3 }}
            lg={{ span: 10, offset: 2 }}
            className="content px-0 mx-0"
          >
            <Switch>
              <Route exact path={path}>
                <Home />
              </Route>
              <Route path={`${path}/users/:id`} component={User} />
              <Route path={`${path}/users`} component={Users} />
              <Route path={`${path}/rides/:id`} component={Ride} />
              <Route path={`${path}/rides`} component={Rides} />
              <Route path={`${path}/bookings/:id`} component={Booking} />
              <Route path={`${path}/bookings`} component={Bookings} />
              <Route path={`${path}/messages/:id`} component={Message} />
              <Route path={`${path}/messages`} component={Messages} />
              <Route path={`${path}/partners/:id`} component={Partner} />
              <Route path={`${path}/partners`} component={Partners} />
              <Route
                path={`${path}/partner-rides/:id`}
                component={PartnerRide}
              />
              <Route path={`${path}/partner-rides`} component={PartnerRides} />
              <Route path={`${path}/requests/:id`} component={Request} />
              <Route path={`${path}/requests`} component={Requests} />
              <Route path={`${path}/locations`} component={Locations} />
              <Route path={`${path}/admins`} component={Admins} />
              <Route path={`${path}/transactions`} component={Transactions} />
            </Switch>
            {/* <Footer /> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;
