// Users.js
import React, { useState } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { useHistory } from "react-router-dom";
import {
  Spinner,
  Badge,
  Image,
  Table,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { getUsers, getUserStats } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";

function Users() {
  const history = useHistory();
  //State
  const [driverStatus, setDriverStatus] = useState(null);
  const [passengerStatus, setPassengerStatus] = useState(null);
  const [active, setActive] = useState(null);
  const [isDeleted, setIsDeleted] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchInput, setSearchInput] = useState("");

  //Queries & Mutations
  //Get Users
  const {
    data,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: [
      "users",
      driverStatus,
      passengerStatus,
      isDeleted,
      searchQuery,
      active,
    ],
    queryFn: ({ pageParam = 0 }) =>
      getUsers({
        page: pageParam,
        driver_status: driverStatus,
        passenger_status: passengerStatus,
        is_deleted: isDeleted,
        search: searchQuery,
        active: active,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.data.length < 20) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  //Ger User Stats
  const {
    isLoading: isLoadingStats,
    isError: isErrorStats,
    data: dataStats,
  } = useQuery({
    queryKey: ["userStats"],
    queryFn: () => getUserStats(),
  });

  //Functions
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchQuery(searchInput);
  };

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">Users</p>
        </div>
        <p className="h2">Users</p>
        {isLoadingStats ? null : isErrorStats ? (
          <p className="breadcrumb-text">Error loading stats</p>
        ) : (
          <div className="d-flex flex-row align-items-center mt-2">
            <p className="breadcrumb-text mx-3">
              <span className="fw-bold">All</span> ({dataStats?.total_users})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setDriverStatus("verified")}
            >
              <span className="fw-bold text-primary">Drivers</span> (
              {dataStats?.total_verified_drivers})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setPassengerStatus("verified")}
            >
              <span className="fw-bold text-primary">Passengers</span> (
              {dataStats?.total_verified_passengers})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setDriverStatus("pending")}
            >
              <span className="fw-bold text-primary">Pending Drivers</span> (
              {dataStats?.total_pending_drivers})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setPassengerStatus("pending")}
            >
              <span className="fw-bold text-primary">Pending Passengers</span> (
              {dataStats?.total_pending_passengers})
            </p>
          </div>
        )}
        <Form className="mb-3" onSubmit={handleSearchSubmit}>
          <Row>
            <Col md={4}>
              <InputGroup size="sm">
                <FormControl
                  type="search"
                  placeholder="Search by Phone Number or Name"
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button variant="secondary" type="submit">
                  Search
                </Button>
              </InputGroup>
            </Col>
            <Col md={8} className="mt-2 mt-md-0">
              <InputGroup className="mb-3">
                <Form.Select
                  size="sm"
                  value={driverStatus}
                  onChange={(e) => setDriverStatus(e.target.value)}
                >
                  <option value="">Driver Status</option>
                  <option value="verified">Verified</option>
                  <option value="pending">Pending</option>
                  <option value="unverified">Unverified</option>
                  <option value="blocked">Blocked</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={passengerStatus}
                  onChange={(e) => setPassengerStatus(e.target.value)}
                >
                  <option value="">Passenger Status</option>
                  <option value="verified">Verified</option>
                  <option value="pending">Pending</option>
                  <option value="unverified">Unverified</option>
                  <option value="blocked">Blocked</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={active}
                  onChange={(e) => setActive(e.target.value)}
                >
                  <option value="">Active Status</option>
                  <option value="new_users">New Users</option>
                  <option value="weekly_active">Weekly Active</option>
                  <option value="monthly_active">Monthly Active</option>
                  <option value="quarterly_active">Quarterly Active</option>
                  <option value="semi_year_active">Semi Year Active</option>
                  <option value="yearly_active">Yearly Active</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={isDeleted}
                  onChange={(e) => setIsDeleted(e.target.value)}
                >
                  <option value="">Is Deleted</option>
                  <option value="t">True</option>
                  <option value="f">False</option>
                </Form.Select>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    setIsDeleted("");
                    setPassengerStatus("");
                    setDriverStatus("");
                    setSearchInput("");
                    setSearchQuery("");
                    setActive("");
                  }}
                >
                  Clear Filters
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
      {status === "error" ? (
        <p className="text-center"> {Functions.handleError(error)}</p>
      ) : status === "pending" ? null : (
        <div className="px-md-5 px-2 mt-md-2 mt-1 bg-white table-header">
          <Table responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Profile Image</th>
                <th>Phone</th>
                <th>Name</th>
                <th>Gender</th>
                <th>Passenger Status</th>
                <th>Driver Status</th>
                <th>Updated At</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {data.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.data.map((user) => (
                    <tr
                      key={user.id}
                      onClick={() =>
                        history.push(`/dashboard/users/${user.id}`)
                      }
                    >
                      <td className="table-text">{user.id}</td>
                      <td className="table-text text-secondary">
                        {user.picture !== null ? (
                          <Image
                            src={process.env.REACT_APP_IMAGE_URL + user.picture}
                            roundedCircle
                            width="50"
                            height="50"
                            className="mr-3"
                          />
                        ) : null}
                      </td>
                      <td className="text-secondary table-text">
                        {user.phone}
                      </td>
                      <td className="table-text">{user.name}</td>
                      <td className="table-text">{user.gender}</td>
                      <td className="table-text">
                        {" "}
                        <Badge
                          bg={
                            user.passenger_status === "verified"
                              ? "primary"
                              : user.passenger_status === "unverified"
                              ? "secondary"
                              : "danger"
                          }
                          className="mt-2"
                        >
                          {user.passenger_status}
                        </Badge>
                      </td>
                      <td className="table-text">
                        <Badge
                          bg={
                            user.driver_status === "verified"
                              ? "primary"
                              : user.driver_status === "unverified"
                              ? "secondary"
                              : "danger"
                          }
                        >
                          {user.driver_status}
                        </Badge>
                      </td>
                      <td className="table-text text-secondary">
                        {user.updated_at !== null
                          ? DateTime.fromISO(user?.updated_at).toLocaleString(
                              DateTime.DATE_MED
                            )
                          : "No update"}
                      </td>
                      <td className="table-text text-secondary">
                        {DateTime.fromISO(user?.created_at).toLocaleString(
                          DateTime.DATE_MED
                        )}
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <div className="py-2 d-flex flex-row justify-content-center">
        <Button
          variant="outline-secondary"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "No More Results"}
        </Button>
      </div>
      <div className="py-2 d-flex flex-row justify-content-center">
        {(isFetching || isLoadingStats || status === "pending") &&
        !isFetchingNextPage ? (
          <Spinner animation="border" />
        ) : null}
      </div>
    </>
  );
}

export default Users;
