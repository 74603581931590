import axios from "axios";
//const BASE_URL = "http://localhost:3004/v1/admin/";
const BASE_URL = "https://api.cabbily.com/v1/admin/";
//const BASE_URL = "https://api-staging.cabbily.com/v1/admin/";
const deviceType = "Desktop";
const os = "Windows";
const osVersion = "1.0";
const signature =
  "z2d5akpzmbn0n0vyjptzlcsi59ucoedy4qir800zpsz9mgx1jbqikfov4zdb";
const appSecret = "kjafhJQKQJNWJNjqnjjqjnnNNWJNJQNJWJNjnqjjNQJNWJNQj";

export const mainApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Origin": true,
    Accept: "*/*",
    "Content-Type": "application/json",
    "device-type": deviceType,
    os: os,
    "os-version": osVersion,
    signature: signature,
    "app-secret": appSecret,
  },
});

mainApi.defaults.headers.common["Content-Type"] = "application/json";

mainApi.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//ADMINS
export const getAdmins = async (params: any) => {
  const response = await mainApi.get(`admins`, { params });
  return response.data;
};

export const getAdmin = async (params: any) => {
  const response = await mainApi.get(`admins/${params.id}`);
  return response.data;
};

export const editAdmin = async (params: any) => {
  const response = await mainApi.put(
    `admins/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

export const addAdmin = async (params: any) => {
  const response = await mainApi.post(`admins`, JSON.stringify(params));
  return response.data;
};

export const deleteAdmin = async (params: any) => {
  const response = await mainApi.delete(
    `admins/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};
export const adminLogin = async (params: any) => {
  const response = await mainApi.post("login", JSON.stringify(params));
  return response.data;
};

//PARTNERS

export const partnerSearch = async (params: any) => {
  const response = await mainApi.get(`partners/search`, { params });
  return response.data;
};

export const getPartners = async (params: any) => {
  const response = await mainApi.get(`partners`, { params });
  return response.data;
};

export const getPartner = async (params: any) => {
  const response = await mainApi.get(`partners/${params.id}`);
  return response.data;
};

export const editPartner = async (params: any) => {
  const response = await mainApi.put(
    `partners/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

export const addPartner = async (params: any) => {
  const response = await mainApi.post(`partners`, JSON.stringify(params));
  return response.data;
};

export const deletePartner = async (params: any) => {
  const response = await mainApi.delete(
    `partners/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

//PARTNER LOCATIONS

export const partnerLocationsSearch = async (params: any) => {
  const response = await mainApi.get(`partner-locations/search`, {
    params,
  });
  return response.data;
};

export const getPartnerLocations = async (params: any) => {
  const response = await mainApi.get(`partner-locations/${params.id}`, {
    params,
  });
  return response.data;
};

export const getPartnerLocation = async (params: any) => {
  const response = await mainApi.get(`partner-locations/single/${params.id}`);
  return response.data;
};

export const editPartnerLocation = async (params: any) => {
  const response = await mainApi.put(
    `partner-locations/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

export const addPartnerLocation = async (params: any) => {
  const response = await mainApi.post(
    `partner-locations`,
    JSON.stringify(params)
  );
  return response.data;
};

export const deletePartnerLocation = async (params: any) => {
  const response = await mainApi.delete(
    `partner-locations/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

//PARTNER RIDES

export const getPartnerRideStats = async (params: any) => {
  const response = await mainApi.get(`partner-rides/stats`);
  return response.data;
};

export const getPartnerRides = async (params: any) => {
  const response = await mainApi.get(`partner-rides`, { params });
  return response.data;
};

export const getPartnerRide = async (params: any) => {
  const response = await mainApi.get(`partner-rides/${params.id}`);
  return response.data;
};

export const editPartnerRide = async (params: any) => {
  const response = await mainApi.put(
    `partner-rides/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

export const addPartnerRide = async (params: any) => {
  const response = await mainApi.post(`partner-rides`, JSON.stringify(params));
  return response.data;
};

export const deletePartnerRide = async (params: any) => {
  const response = await mainApi.delete(
    `partner-rides/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

//USERS

export const userSearch = async (params: any) => {
  const response = await mainApi.get(`users/search`, { params });
  return response.data;
};

export const getUsers = async (params: any) => {
  const response = await mainApi.get(`users`, { params });
  return response.data;
};

export const getUser = async (params: any) => {
  const response = await mainApi.get(`users/${params.id}`);
  return response.data;
};

export const getUserStats = async (params: any) => {
  const response = await mainApi.get(`users/stats`);
  return response.data;
};

export const updateDriverStatus = async (params: any) => {
  const response = await mainApi.put(
    `users/driver-status/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

export const updatePassengerStatus = async (params: any) => {
  const response = await mainApi.put(
    `users/passenger-status/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

export const addCredits = async (params: any) => {
  const response = await mainApi.put(
    `users/add-credits/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

export const addCash = async (params: any) => {
  const response = await mainApi.put(
    `users/add-cash/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

//RIDES
export const getRides = async (params: any) => {
  const response = await mainApi.get(`rides`, { params });
  return response.data;
};

export const getRideStats = async (params: any) => {
  const response = await mainApi.get(`rides/stats`);
  return response.data;
};

export const getRide = async (params: any) => {
  const response = await mainApi.get(`rides/${params.id}`);
  return response.data;
};

export const updateRideStatus = async (params: any) => {
  const response = await mainApi.put(
    `rides/status/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

//BOOKINGS
export const getBookings = async (params: any) => {
  const response = await mainApi.get(`bookings`, { params });
  return response.data;
};

export const getBookingStats = async (params: any) => {
  const response = await mainApi.get(`bookings/stats`);
  return response.data;
};

export const getBooking = async (params: any) => {
  const response = await mainApi.get(`bookings/${params.id}`);
  return response.data;
};

export const updateBookingStatus = async (params: any) => {
  const response = await mainApi.put(
    `bookings/status/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

//LOCATIONS
export const locationSearch = async (params: any) => {
  const response = await mainApi.get(`locations/search`, { params });
  return response.data;
};

export const getLocations = async (params: any) => {
  const response = await mainApi.get(`locations`, { params });
  return response.data;
};

export const getLocation = async (params: any) => {
  const response = await mainApi.get(`locations/${params.id}`);
  return response.data;
};

export const editLocation = async (params: any) => {
  const response = await mainApi.put(
    `locations/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

export const addLocation = async (params: any) => {
  const response = await mainApi.post(`locations`, JSON.stringify(params));
  return response.data;
};

export const deleteLocation = async (params: any) => {
  const response = await mainApi.delete(
    `locations/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

//MESSAGES
export const getMessages = async (params: any) => {
  const response = await mainApi.get(`messages`, { params });
  return response.data;
};

export const getMessageStats = async () => {
  const response = await mainApi.get(`messages/stats`);
  return response.data;
};

export const getMessage = async (params: any) => {
  const response = await mainApi.get(`messages/${params.id}`);
  return response.data;
};

export const addMessage = async (params: any) => {
  const response = await mainApi.post(`messages`, JSON.stringify(params));
  return response.data;
};

export const addMessageSingle = async (params: any) => {
  const response = await mainApi.post(
    `messages/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

export const getMessageCounts = async (params: any) => {
  const response = await mainApi.put(`messages`, JSON.stringify(params));
  return response.data;
};

//TRANSACTIONS

export const getTransactionStats = async () => {
  const response = await mainApi.get(`transactions/stats`);
  return response.data;
};

export const getTransactions = async (params: any) => {
  const response = await mainApi.get(`transactions`, { params });
  return response.data;
};

export const getTransaction = async (params: any) => {
  const response = await mainApi.get(`transactions/${params.id}`);
  return response.data;
};

export const updateTransactionStatus = async (params: any) => {
  const response = await mainApi.put(
    `transactions/status/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};

export const refundTransaction = async (params: any) => {
  const response = await mainApi.post(`transactions`, JSON.stringify(params));
  return response.data;
};

//IMAGES

//Upload File
export const uploadImageAdmin = async (params: any) => {
  const response = await mainApi.post(
    `/images?asset_type=${params.asset_type}`,
    params.body,
    {
      headers: { "Content-Type": "multipart/form-data" },
    }
  );
  return response.data;
};

//REQUESTS
export const getRequests = async (params: any) => {
  const response = await mainApi.get(`requests`, { params });
  return response.data;
};

export const getRequestStats = async (params: any) => {
  const response = await mainApi.get(`requests/stats`);
  return response.data;
};

export const getRequest = async (params: any) => {
  const response = await mainApi.get(`requests/${params.id}`);
  return response.data;
};

export const updateRequestStatus = async (params: any) => {
  const response = await mainApi.put(
    `requests/status/${params.id}`,
    JSON.stringify(params)
  );
  return response.data;
};
