import { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  InputGroup,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import {
  editPartnerRide,
  partnerSearch,
  partnerLocationsSearch,
  locationSearch,
} from "../../api/mainApi";
import { Functions } from "../../utilities";
import { useForm, Controller, useFieldArray } from "react-hook-form";

const EditModal = ({ show, setShow, itemData }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [charCount, setCharCount] = useState(160);

  const handleClose = () => {
    reset();
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false);
  };

  //Form initiation
  const {
    control,
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    defaultValues: {
      id: "",
      partner_id: "",
      origin: null,
      destination: null,
      partner_location_id: "",
      seats: "",
      repeat: "",
      amount: "",
      boarding_info: "",
      is_delayed: "",
      delayed_reason: "",
      route_type: "",
      ride_type: "",
      status: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "departure",
  });

  const watchBoardingInfo = watch("boarding_info", "");
  const watchPartner = watch("partner_id", null);
  const watchRepeat = watch("repeat", null);

  useEffect(() => {
    setCharCount(160 - watchBoardingInfo.length);
  }, [watchBoardingInfo]);

  const onSubmit = (data) => {
    editPartnerRideMutation.mutate(data);
  };

  // Queries & Mutations
  const queryClient = useQueryClient();

  //Partner Search
  const { isLoading: isLoadingPartners, data: dataPartners } = useQuery({
    queryKey: ["searched-partners"],
    queryFn: async () => await partnerSearch(),
  });

  useEffect(() => {
    if (show) {
      reset({
        id: itemData?.id,
        partner_id: itemData?.partner_id,
        origin: itemData?.origin,
        destination: itemData?.destination,
        partner_location_id: itemData?.partner_location_id,
        seats: itemData?.seats,
        departure: itemData?.departure,
        repeat: itemData?.repeat,
        amount: itemData?.amount,
        boarding_info: itemData?.boarding_info,
        is_delayed: itemData?.is_delayed,
        delayed_reason: itemData?.delayed_reason,
        route_type: itemData?.route_type,
        ride_type: itemData?.ride_type,
        status: itemData?.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  //Partner Location Search
  const { isLoading: isLoadingPartnerLocations, data: dataPartnerLocations } =
    useQuery({
      queryKey: ["searched-partner-locations", watchPartner],
      queryFn: async () =>
        await partnerLocationsSearch({
          id: watchPartner,
        }),
    });

  //Location search
  const { isLoading: isLoadingLocations, data: dataLocations } = useQuery({
    queryKey: ["searched-locations"],
    queryFn: async () => await locationSearch(),
  });

  //Update Status
  const editPartnerRideMutation = useMutation({
    mutationFn: (data) => editPartnerRide(data),
    onSuccess: (dataResponse) => {
      setErrorMessage("");
      setSuccessMessage("Edited successfully!");
      queryClient.invalidateQueries("partner-rides");
    },
    onError(error: any) {
      setSuccessMessage("");
      setErrorMessage(Functions.handleError(error));
    },
  });

  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        contentClassName="bg-white"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Partner Ride</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
            {successMessage && (
              <span className="text-primary">{successMessage}</span>
            )}
            <Row>
              <Form.Label>Select Partner & Location</Form.Label>
              <Col>
                {!isLoadingPartners && dataPartners && (
                  <Form.Group controlId="partner" className="mt-2">
                    <InputGroup className="mb-3">
                      <Form.Select
                        {...register("partner_id", {
                          required: "Partner is required",
                        })}
                      >
                        <option value="">Select Partner</option>
                        {dataPartners?.map((item, index) => {
                          return (
                            <option value={item?.id}>
                              {item?.company_name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </InputGroup>
                    {errors?.partner_id && (
                      <span className="text-danger">
                        {errors?.partner_id?.message}
                      </span>
                    )}
                  </Form.Group>
                )}
              </Col>
              <Col>
                {isLoadingPartnerLocations && <Spinner animation="border" />}
                {!isLoadingPartnerLocations && dataPartnerLocations && (
                  <Form.Group controlId="partner" className="mt-2">
                    <InputGroup className="mb-3">
                      <Form.Select
                        {...register("partner_location_id", {
                          required: "Partner Location is required",
                        })}
                      >
                        <option value="">Select Partner Location</option>
                        {dataPartnerLocations?.map((item, index) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </Form.Select>
                    </InputGroup>
                    {errors?.partner_location_id && (
                      <span className="text-danger">
                        {errors?.partner_location_id?.message}
                      </span>
                    )}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Row>
              <Form.Label>Select Route - Origin & Destination</Form.Label>
              <Col>
                {!isLoadingLocations && dataLocations && (
                  <Form.Group controlId="partner" className="mt-2">
                    <InputGroup className="mb-3">
                      <Form.Select
                        {...register("origin", {
                          required: "Origin is required",
                        })}
                      >
                        <option value="">Select Origin</option>
                        {dataLocations?.map((item, index) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </Form.Select>
                    </InputGroup>
                    {errors?.origin && (
                      <span className="text-danger">
                        {errors?.origin?.message}
                      </span>
                    )}
                  </Form.Group>
                )}
              </Col>
              <Col>
                {!isLoadingLocations && dataLocations && (
                  <Form.Group controlId="partner" className="mt-2">
                    <InputGroup className="mb-3">
                      <Form.Select
                        {...register("destination", {
                          required: "Destination is required",
                        })}
                      >
                        <option value="">Select Destination</option>
                        {dataLocations?.map((item, index) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </Form.Select>
                    </InputGroup>
                    {errors?.destination && (
                      <span className="text-danger">
                        {errors?.destination?.message}
                      </span>
                    )}
                  </Form.Group>
                )}
              </Col>
            </Row>
            <Form.Group controlId="ride-status" className="mt-2">
              <Form.Label>Repeat</Form.Label>
              <InputGroup className="mb-3">
                <Form.Select
                  {...register("repeat", {
                    required: "Repeat is required",
                  })}
                >
                  <option value="">Repeat Ride</option>
                  <option value="daily">Daily</option>
                  <option value="weekly">Weekly</option>
                </Form.Select>
              </InputGroup>
              {errors?.repeat && (
                <span className="text-danger">{errors?.repeat?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="departure" className="mt-2">
              <Form.Label>Departure</Form.Label>
            </Form.Group>
            {fields.map((item, index) => (
              <div key={item.id}>
                <Row className="mt-2">
                  <Col>
                    <Controller
                      control={control}
                      name={`departure.${index}.time`}
                      rules={{
                        required: "Departure time is required.",
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                          type="time"
                          placeholder="Time"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                      )}
                    />
                  </Col>
                  {watchRepeat === "weekly" && (
                    <Col>
                      <Controller
                        control={control}
                        name={`departure.${index}.day`}
                        rules={{
                          required: "Departure day is required.",
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <Form.Select
                            onChange={onChange}
                            onBlur={onBlur}
                            value={value}
                          >
                            <option value="">Day</option>
                            <option value="Monday">Monday</option>
                            <option value="Tuesday">Tuesday</option>
                            <option value="Wednesday">Wednesday</option>
                            <option value="Thursday">Thursday</option>
                            <option value="Friday">Friday</option>
                            <option value="Saturday">Saturday</option>
                            <option value="Sunday">Sunday</option>
                          </Form.Select>
                        )}
                      />
                    </Col>
                  )}

                  <Col>
                    <Button onClick={() => remove(index)}>Remove</Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      {errors.departure?.[index]?.time && (
                        <span className="text-danger">
                          {errors.departure?.[index].time?.message}
                        </span>
                      )}
                      {errors.departure?.[index]?.day && (
                        <span className="text-danger">
                          {errors.departure?.[index].day?.message}
                        </span>
                      )}
                    </p>
                  </Col>
                </Row>
              </div>
            ))}
            <Row className="my-2">
              <Col>
                <Button onClick={() => append({ time: "", day: "" })}>
                  Add Departure Time
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="ride-status" className="mt-2">
                  <Form.Label>Route Type</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Select
                      {...register("route_type", {
                        required: "Route Type is required",
                      })}
                    >
                      <option value="">Route Type</option>
                      <option value="standard">Standard</option>
                      <option value="custom">Custom</option>
                    </Form.Select>
                  </InputGroup>
                  {errors?.route_type && (
                    <span className="text-danger">
                      {errors?.route_type?.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="ride-status" className="mt-2">
                  <Form.Label>Ride Type</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Select
                      {...register("ride_type", {
                        required: "Ride Type is required",
                      })}
                    >
                      <option value="">Ride Type</option>
                      <option value="economy">Economy</option>
                      <option value="business">Business</option>
                      <option value="VIP">VIP</option>
                    </Form.Select>
                  </InputGroup>
                  {errors?.ride_type && (
                    <span className="text-danger">
                      {errors?.ride_type?.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Form.Group controlId="message" className="mt-2">
              <Form.Label>Boarding Info</Form.Label>
              <Form.Control
                as="textarea"
                {...register("boarding_info", {
                  required: "Boarding info is required",
                  maxLength: {
                    value: 160,
                    message:
                      "Boarding info cannot exceed 160 characters (SMS Length)",
                  },
                })}
                rows={3}
                maxLength="160"
                placeholder="e.g. Show bus ticket with ID at bus park..."
              />

              <Form.Text className="text-primary">
                {charCount} characters remaining
              </Form.Text>
              {errors?.boarding_info && (
                <span className="text-danger">
                  {errors?.boarding_info?.message}
                </span>
              )}
            </Form.Group>
            <Row>
              <Col>
                <Form.Group controlId="amount" className="mt-2">
                  <Form.Label>Seats</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="seats bus has"
                    {...register("seats")}
                  />
                  {errors?.seats && (
                    <span className="text-danger">
                      {errors?.seats?.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="amount" className="mt-2">
                  <Form.Label>Cost Per Seat</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="cost per seat"
                    {...register("amount")}
                  />
                  {errors?.company_name && (
                    <span className="text-danger">
                      {errors?.amount?.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="ride-status" className="mt-2">
                  <Form.Label>Status</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Select
                      {...register("status", {
                        required: "Status is required",
                      })}
                    >
                      <option value="">Status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Form.Select>
                  </InputGroup>
                  {errors?.status && (
                    <span className="text-danger">
                      {errors?.status?.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="ride-status" className="mt-2">
                  <Form.Label>Is Ride Delayed</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Select {...register("is_delayed")}>
                      <option value="">Is Delayed</option>
                      <option value="t">True</option>
                      <option value="f">False</option>
                    </Form.Select>
                  </InputGroup>
                  {errors?.is_delayed && (
                    <span className="text-danger">
                      {errors?.is_delayed?.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="amount" className="mt-2">
                  <Form.Label>Delayed Reason</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="reason for delay"
                    {...register("delayed_reason")}
                  />
                  {errors?.delayed_reason && (
                    <span className="text-danger">
                      {errors?.delayed_reason?.message}
                    </span>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Edit Ride
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditModal;
