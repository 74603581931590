import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";

function Header() {
  const history = useHistory();
  return (
    <Navbar bg="white" data-bs-theme="light" className="navbar-top" fixed="top">
      <Navbar.Brand
        onClick={() => {
          history.push("/dashboard");
        }}
      >
        Cabbily Admin
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end">
        <Nav>
          <Button
            onClick={() => {
              history.push("/logout");
            }}
            className="fw-bold"
          >
            LOGOUT
          </Button>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Header;
