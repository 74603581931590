import React, { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import {
  Spinner,
  Badge,
  Table,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import { getAdmins } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import DeleteModal from "./DeleteModal";

const Admins = () => {
  //State
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [itemData, setItemData] = useState(null);

  //Queries & Mutations
  //Get Admins
  const {
    data,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: ["admins", searchQuery],
    queryFn: ({ pageParam = 0 }) =>
      getAdmins({
        page: pageParam,
        search: searchQuery,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.data.length < 20) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  //Functions
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchQuery(searchInput);
  };

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">Admins</p>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <p className="h2">Admins</p>
          <Button variant="outline-secondary" onClick={() => setShowAdd(true)}>
            Add New
          </Button>
        </div>
        <Form className="mb-3" onSubmit={handleSearchSubmit}>
          <Row>
            <Col md={4}>
              <InputGroup size="sm">
                <FormControl
                  type="search"
                  placeholder="Search by username"
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button variant="secondary" type="submit">
                  Search
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
      {status === "error" ? (
        <p className="text-center"> {Functions.handleError(error)}</p>
      ) : status === "pending" ? null : (
        <div className="px-md-5 px-2 mt-md-2 mt-1 bg-white table-header">
          <Table responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th>Username</th>
                <th>Phone</th>
                <th>Role</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.data.map((item) => (
                    <tr key={item.id}>
                      <td className="table-text">{item.id}</td>
                      <td className="table-text text-secondary">
                        {item?.username}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.phone}
                      </td>
                      <td className="table-text">
                        <Badge bg="secondary" className="mt-2">
                          {item?.role}
                        </Badge>
                      </td>
                      <td className="table-text text-secondary">
                        {DateTime.fromISO(item?.created_at).toLocaleString(
                          DateTime.DATE_MED
                        )}
                      </td>
                      <td className="table-text text-secondary">
                        {item?.updated_at !== null
                          ? DateTime.fromISO(item?.updated_at).toLocaleString(
                              DateTime.DATE_MED
                            )
                          : "No update"}
                      </td>
                      <td className="table-text text-secondary">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id="dropdown-basic"
                          >
                            Actions
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="bg-white">
                            <Dropdown.Item
                              onClick={() => {
                                setItemData(item);
                                setShowEdit(true);
                              }}
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => {
                                setItemData(item);
                                setShowDelete(true);
                              }}
                            >
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <div className="py-2 d-flex flex-row justify-content-center">
        <Button
          variant="outline-secondary"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "No More Results"}
        </Button>
      </div>
      <div className="py-2 d-flex flex-row justify-content-center">
        {(isFetching || status === "pending") && !isFetchingNextPage ? (
          <Spinner animation="border" />
        ) : null}
      </div>
      <AddModal setShow={setShowAdd} show={showAdd} />
      <EditModal setShow={setShowEdit} show={showEdit} itemData={itemData} />
      <DeleteModal
        setShow={setShowDelete}
        show={showDelete}
        itemData={itemData}
      />
    </>
  );
};

export default Admins;
