import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { Container, Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { adminLogin } from "../api/mainApi";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import { Functions } from "../utilities";

const Login = () => {
  const history = useHistory();
  const { setToken } = useContext(AuthContext);
  const initState = {
    username: "",
    password: "",
  };

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: initState,
  });

  const onSubmit = (data) => {
    // You can submit the form data or perform any other actions here
    const params = {
      username: data?.username,
      password: data?.password,
    };
    userLoginMutation.mutate(params);
  };

  // Queries
  //Mutate registration invite
  const userLoginMutation = useMutation({
    mutationFn: (data) => adminLogin(data),
    onSuccess: (dataResponse) => {
      setToken(dataResponse);
      localStorage.setItem("token", dataResponse);
      history.push("/dashboard");
    },
    onError(error: any) {
      setToken(null);
      localStorage.removeItem("token");
      setErrorMessage(Functions.handleError(error));
    },
  });
  return (
    <div className="login-container">
      <Container fluid className="h-100">
        <Row className="h-100">
          <Col
            xs={12}
            md={6}
            className="welcome-section d-none d-md-flex"
            style={{
              backgroundImage: "url(/images/background-tile.png)",
            }}
          ></Col>
          <Col
            xs={12}
            md={6}
            className="form-section d-flex align-items-center justify-content-center"
          >
            <Form onSubmit={handleSubmit(onSubmit)} className="login-form">
              <h1 className="text-primary">Cabbily Admin</h1>
              {errorMessage && (
                <span className="text-danger">{errorMessage}</span>
              )}
              <Form.Group controlId="username" className="mt-2">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  className="bg-white"
                  placeholder="Enter username"
                  {...register("username", {
                    required: "Username is required",
                  })}
                />
                {errors?.username && (
                  <span className="text-danger">
                    {errors?.username?.message}
                  </span>
                )}
              </Form.Group>

              <Form.Group controlId="password" className="mt-2">
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    className="bg-white"
                    type={passwordVisible ? "text" : "password"}
                    placeholder="Password"
                    name="password"
                    {...register("password", {
                      required: "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters",
                      },
                    })}
                  />
                  <InputGroup.Text
                    id="basic-addon2"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                  >
                    {passwordVisible ? (
                      <EyeSlash size={18} />
                    ) : (
                      <Eye size={18} />
                    )}
                  </InputGroup.Text>
                </InputGroup>
                {errors?.password && (
                  <span className="text-danger">
                    {errors?.password?.message}
                  </span>
                )}
              </Form.Group>
              <div className="d-grid gap-2">
                <Button
                  variant="primary"
                  type="submit"
                  className="mt-4"
                  size="lg"
                >
                  Login
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
