import { useState } from "react";
import { Form, Button, InputGroup, Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addAdmin } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { useForm } from "react-hook-form";
import { Eye, EyeSlash } from "react-bootstrap-icons";

const AddModal = ({ show, setShow }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handleClose = () => {
    reset();
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false);
  };

  //Form initiation
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: {
      phone: "",
      username: "",
      password: "",
      role: "",
    },
  });

  const onSubmit = (data) => {
    addMutation.mutate(data);
  };

  // Queries & Mutations
  const queryClient = useQueryClient();

  //Update Status
  const addMutation = useMutation({
    mutationFn: (data) => addAdmin(data),
    onSuccess: (dataResponse) => {
      setErrorMessage("");
      setSuccessMessage("Admin added successfully.");
      queryClient.invalidateQueries("admins");
    },
    onError(error: any) {
      setSuccessMessage("");
      setErrorMessage(Functions.handleError(error));
    },
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        contentClassName="bg-white"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Admin</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
            {successMessage && (
              <span className="text-primary">{successMessage}</span>
            )}
            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="username"
                {...register("username")}
              />
              {errors?.username && (
                <span className="text-danger">{errors?.username?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Phone</Form.Label>
              <Form.Control
                type="text"
                placeholder="phone"
                {...register("phone")}
              />
              {errors?.phone && (
                <span className="text-danger">{errors?.phone?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="password" className="mt-2">
              <Form.Label>Password</Form.Label>
              <InputGroup>
                <Form.Control
                  className="bg-white"
                  type={passwordVisible ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  {...register("password", {
                    required: "Password is required",
                    minLength: {
                      value: 6,
                      message: "Password must be at least 6 characters",
                    },
                  })}
                />
                <InputGroup.Text
                  id="basic-addon2"
                  onClick={() => setPasswordVisible(!passwordVisible)}
                >
                  {passwordVisible ? <EyeSlash size={18} /> : <Eye size={18} />}
                </InputGroup.Text>
              </InputGroup>
              {errors?.password && (
                <span className="text-danger">{errors?.password?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="driver-status" className="mt-2">
              <Form.Label>Role</Form.Label>
              <InputGroup className="mb-3">
                <Form.Select
                  {...register("role", {
                    required: "Role is required",
                  })}
                >
                  <option value="">Role</option>
                  <option value="admin">Admin</option>
                  <option value="superAdmin">Super Admin</option>
                </Form.Select>
              </InputGroup>
              {errors?.role && (
                <span className="text-danger">{errors?.role?.message}</span>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddModal;
