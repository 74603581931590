import "./App.scss";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

//Pages
import Dashboard from "./pages/dashboard/Dashboard";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import { AuthProvider } from "./context/AuthContext";

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

function App() {
  return (
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Router>
          <Switch>
            <Route path="/dashboard">
              <Dashboard />
            </Route>
            <Route path="/logout">
              <Logout />
            </Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </Router>
      </QueryClientProvider>
    </AuthProvider>
  );
}

export default App;
