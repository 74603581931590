// Transactions.js
import React, { useState } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import {
  Spinner,
  Badge,
  Table,
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  Dropdown,
} from "react-bootstrap";
import { getTransactions, getTransactionStats } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";
import numbro from "numbro";
import TransactionStatusModal from "./TransactionStatusModal";
import AddRefund from "./AddRefund";

function Transactions() {
  //State
  const [transactionType, setTransactionType] = useState(null);
  const [transactionMethod, setTransactionMethod] = useState(null);
  const [transactionStatus, setTransactionStatus] = useState(null);
  const [searchQuery, setSearchQuery] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [showStatus, setShowStatus] = useState(false);
  const [showAdd, setShowAdd] = useState(false);
  const [itemData, setItemData] = useState(null);

  //Queries & Mutations
  //Get Transactions
  const {
    data,
    error,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery({
    queryKey: [
      "transactions",
      transactionType,
      transactionMethod,
      transactionStatus,
      searchQuery,
    ],
    queryFn: ({ pageParam = 0 }) =>
      getTransactions({
        page: pageParam,
        transaction_type: transactionType,
        transaction_method: transactionMethod,
        transaction_status: transactionStatus,
        search: searchQuery,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      if (lastPage.data.length < 20) {
        return undefined;
      }
      return lastPageParam + 1;
    },
  });

  //Ger Transaction Stats
  const {
    isLoading: isLoadingStats,
    isError: isErrorStats,
    data: dataStats,
  } = useQuery({
    queryKey: ["transactionStats"],
    queryFn: () => getTransactionStats(),
  });

  //Functions
  const handleSearchSubmit = (e) => {
    e.preventDefault();
    setSearchQuery(searchInput);
  };

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">Transactions</p>
        </div>
        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
          <p className="h2">Transactions</p>
          <Button variant="outline-secondary" onClick={() => setShowAdd(true)}>
            Refund User
          </Button>
        </div>
        {isLoadingStats ? null : isErrorStats ? (
          <p className="breadcrumb-text">Error loading stats</p>
        ) : (
          <div className="d-flex flex-row align-items-center mt-2">
            <p className="breadcrumb-text mx-3">
              <span className="fw-bold">All</span> (
              {dataStats?.total_transactions})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setTransactionStatus("COMPLETED")}
            >
              <span className="fw-bold text-primary">Completed</span> (
              {dataStats?.total_completed})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setTransactionStatus("PENDING")}
            >
              <span className="fw-bold text-primary">Pending</span> (
              {dataStats?.total_pending})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setTransactionStatus("FAILED")}
            >
              <span className="fw-bold text-primary">Failed</span> (
              {dataStats?.total_failed})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setTransactionMethod("cash")}
            >
              <span className="fw-bold text-primary">Cash Payments</span> (
              {dataStats?.total_cash})
            </p>
            <p
              className="breadcrumb-text mx-3"
              onClick={() => setTransactionMethod("wallet")}
            >
              <span className="fw-bold text-primary">Wallet Payments</span> (
              {dataStats?.total_wallet})
            </p>
          </div>
        )}
        <Form className="mb-3" onSubmit={handleSearchSubmit}>
          <Row>
            <Col md={4}>
              <InputGroup size="sm">
                <FormControl
                  type="search"
                  placeholder="Search by transaction ID, merchant reference, msisdn"
                  aria-label="Search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
                <Button variant="secondary" type="submit">
                  Search
                </Button>
              </InputGroup>
            </Col>
            <Col md={6} className="mt-2 mt-md-0">
              <InputGroup className="mb-3">
                <Form.Select
                  size="sm"
                  value={transactionType}
                  onChange={(e) => setTransactionType(e.target.value)}
                >
                  <option value="">Type</option>
                  <option value="deposit">Deposit</option>
                  <option value="withdraw">Withdraw</option>
                  <option value="payment">Payment</option>
                  <option value="receipt">Receipt</option>
                  <option value="refund">Refund</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={transactionMethod}
                  onChange={(e) => setTransactionMethod(e.target.value)}
                >
                  <option value="">Method</option>
                  <option value="wallet">Wallet</option>
                  <option value="cash">Cash</option>
                </Form.Select>
                <Form.Select
                  size="sm"
                  value={transactionStatus}
                  onChange={(e) => setTransactionStatus(e.target.value)}
                >
                  <option value="">Status</option>
                  <option value="PENDING">Pending</option>
                  <option value="COMPLETED">Completed</option>
                  <option value="FAILED">Failed</option>
                </Form.Select>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    setTransactionStatus("");
                    setTransactionMethod("");
                    setTransactionType("");
                    setSearchInput("");
                    setSearchQuery("");
                  }}
                >
                  Clear Filters
                </Button>
              </InputGroup>
            </Col>
          </Row>
        </Form>
      </div>
      {status === "error" ? (
        <p className="text-center"> {Functions.handleError(error)}</p>
      ) : status === "pending" ? null : (
        <div className="px-md-5 px-2 mt-md-2 mt-1 bg-white table-header">
          <Table responsive hover>
            <thead>
              <tr>
                <th>#</th>
                <th className="no-wrap">Merchant Reference</th>
                <th className="no-wrap">Transaction Id</th>
                <th className="no-wrap">User Id</th>
                <th className="no-wrap">Ride Id</th>
                <th className="no-wrap">Booking Id</th>
                <th className="no-wrap">Msisdn</th>
                <th className="no-wrap">Type</th>
                <th className="no-wrap">Method</th>
                <th className="no-wrap">Amount</th>
                <th className="no-wrap">Platform Fees</th>
                <th className="no-wrap">Transaction Fees</th>
                <th className="no-wrap">Credits Applied</th>
                <th>Status</th>
                <th className="no-wrap">Updated At</th>
                <th className="no-wrap">Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {data.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.data.map((item) => (
                    <tr key={item?.id}>
                      <td className="table-text">{item?.id}</td>
                      <td className="table-text text-secondary breadcrumb-text">
                        {item?.merchant_reference}
                      </td>
                      <td className="table-text text-secondary breadcrumb-text">
                        {item?.transaction_id}
                      </td>
                      <td className="text-secondary table-text">
                        {item?.user_id}
                      </td>
                      <td className="text-secondary table-text">
                        {item?.ride_id}
                      </td>
                      <td className="text-secondary table-text">
                        {item?.booking_id}
                      </td>
                      <td className="table-text">{item?.msisdn}</td>
                      <td className="table-text">
                        <Badge bg="secondary" className="mt-2">
                          {item?.transaction_type}
                        </Badge>
                      </td>
                      <td className="table-text">
                        <Badge bg="secondary" className="mt-2">
                          {item?.transaction_method}
                        </Badge>
                      </td>
                      <td className="text-secondary table-text no-wrap">
                        {item?.amount &&
                          numbro(item?.amount).format({
                            thousandSeparated: true,
                          })}{" "}
                        UGX
                      </td>
                      <td className="text-secondary table-text no-wrap">
                        {item?.platform_fees &&
                          numbro(item?.platform_fees).format({
                            thousandSeparated: true,
                          })}{" "}
                        UGX
                      </td>
                      <td className="text-secondary table-text no-wrap">
                        {item?.transaction_fees &&
                          numbro(item?.transaction_fees).format({
                            thousandSeparated: true,
                          })}{" "}
                        UGX
                      </td>
                      <td className="text-secondary table-text no-wrap">
                        {item?.credits_applied &&
                          numbro(item?.credits_applied).format({
                            thousandSeparated: true,
                          })}{" "}
                        UGX
                      </td>

                      <td className="table-text">
                        <Badge
                          bg={
                            item?.status === "COMPLETED"
                              ? "primary"
                              : item?.status === "PENDING"
                              ? "secondary"
                              : "danger"
                          }
                          className="mt-2"
                        >
                          {item?.status}
                        </Badge>
                      </td>
                      <td className="table-text text-secondary breadcrumb-text no-wrap">
                        {DateTime.fromISO(item?.created_at).toLocaleString(
                          DateTime.DATETIME_MED
                        )}
                      </td>
                      <td className="table-text text-secondary breadcrumb-text no-wrap">
                        {item?.updated_at !== null
                          ? DateTime.fromISO(item?.updated_at).toLocaleString(
                              DateTime.DATETIME_MED
                            )
                          : "No update"}
                      </td>
                      <td>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id="dropdown-basic"
                          >
                            Actions
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="bg-white">
                            <Dropdown.Item
                              onClick={() => {
                                setItemData(item);
                                setShowStatus(true);
                              }}
                            >
                              Update Status
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </Table>
        </div>
      )}
      <div className="py-2 d-flex flex-row justify-content-center">
        <Button
          variant="outline-secondary"
          onClick={() => fetchNextPage()}
          disabled={!hasNextPage || isFetchingNextPage}
        >
          {isFetchingNextPage
            ? "Loading more..."
            : hasNextPage
            ? "Load More"
            : "No More Results"}
        </Button>
      </div>
      <div className="py-2 d-flex flex-row justify-content-center">
        {(isFetching || isLoadingStats || status === "pending") &&
        !isFetchingNextPage ? (
          <Spinner animation="border" />
        ) : null}
      </div>
      <TransactionStatusModal
        setShow={setShowStatus}
        show={showStatus}
        itemData={itemData}
      />
      <AddRefund setShow={setShowAdd} show={showAdd} />
    </>
  );
}

export default Transactions;
