import { useEffect, useState } from "react";
import {
  Form,
  Button,
  Modal,
  Alert,
  FormControl,
  ListGroup,
  Spinner,
  Badge,
  Card,
} from "react-bootstrap";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { addMessageSingle, userSearch } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { useForm } from "react-hook-form";

const AddMessageSingle = ({ show, setShow }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [charCount, setCharCount] = useState(160);
  const [searchQuery, setSearchQuery] = useState(null);

  const handleClose = () => {
    reset();
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false);
  };

  //Form initiation
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    defaultValues: {
      message: "",
      send_notification: "",
      send_sms: "",
      user: null,
    },
  });

  const watchMessage = watch("message", "");
  const watchUser = watch("user", null);

  useEffect(() => {
    setCharCount(160 - watchMessage.length);
  }, [watchMessage]);

  const onSubmit = (data) => {
    let params = {
      message: data.message,
      send_notification: data.send_notification === true ? "t" : "f",
      send_sms: data.send_sms === true ? "t" : "f",
      id: data?.user?.id,
    };
    addMessageMutation.mutate(params);
  };

  // Queries & Mutations
  const queryClient = useQueryClient();

  //User Search
  const {
    isLoading: isLoadingUsers,
    isError: isErrorUsers,
    error: errorUsers,
    data: dataUsers,
  } = useQuery({
    queryKey: ["searched-users", searchQuery],
    queryFn: async () => await userSearch({ search: searchQuery }),
  });

  //Update Status
  const addMessageMutation = useMutation({
    mutationFn: (data) => addMessageSingle(data),
    onSuccess: (dataResponse) => {
      setErrorMessage("");
      setSuccessMessage("Updated successfully!");
      queryClient.invalidateQueries("messages");
    },
    onError(error: any) {
      setSuccessMessage("");
      setErrorMessage(Functions.handleError(error));
    },
  });

  return (
    <>
      <Modal
        show={show}
        size="lg"
        onHide={handleClose}
        backdrop="static"
        contentClassName="bg-white"
      >
        <Modal.Header closeButton>
          <Modal.Title>Send to Single User</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
            {successMessage && (
              <span className="text-primary">{successMessage}</span>
            )}

            <Form.Group controlId="message" className="mt-2">
              <Form.Label>Message</Form.Label>
              <Form.Control
                as="textarea"
                {...register("message", {
                  required: "Message is required",
                  maxLength: {
                    value: 160,
                    message:
                      "Message cannot exceed 160 characters (SMS Length)",
                  },
                })}
                rows={3}
                maxLength="160"
                placeholder="Type your message here..."
              />

              <Form.Text className="text-primary">
                {charCount} characters remaining
              </Form.Text>
              {errors?.driver_status && (
                <span className="text-danger">{errors?.message?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="message" className="mt-2">
              <Form.Label>Message Type</Form.Label>
              <Form.Check // prettier-ignore
                type="switch"
                id="send-sms-switch"
                label="Send SMS Message"
                {...register("send_sms")}
                onChange={(e) => setValue("send_sms", e.target.checked)}
              />
              <Form.Check // prettier-ignore
                type="switch"
                id="send-notification-switch"
                label="Send Notification"
                {...register("send_notification")}
                onChange={(e) =>
                  setValue("send_notification", e.target.checked)
                }
              />
            </Form.Group>
            <Form.Group controlId="message" className="mt-2">
              <Form.Label>Select User</Form.Label>
              {watchUser !== null && (
                <Alert variant="primary">
                  {watchUser?.name} | {watchUser?.phone} |{" "}
                  {watchUser?.notification_token !== null ? (
                    <Badge variant="primary">notifications-enabled</Badge>
                  ) : (
                    <Badge variant="secondary">notifications-disabled</Badge>
                  )}
                </Alert>
              )}

              <FormControl
                type="search"
                placeholder="Search by id, name or phone"
                aria-label="Search"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <Card className="mt-2">
                {isLoadingUsers ? (
                  <Spinner animation="border" />
                ) : isErrorUsers ? (
                  <p>{Functions.handleError(errorUsers)}</p>
                ) : (
                  <ListGroup variant="flush">
                    {dataUsers.map((item, index) => {
                      return (
                        <ListGroup.Item
                          key={item.id}
                          onClick={() => setValue("user", item)}
                        >
                          {item?.name} | {item?.phone} |{" "}
                          {item?.notification_token !== null ? (
                            <Badge variant="primary">
                              notifications-enabled
                            </Badge>
                          ) : (
                            <Badge variant="secondary">
                              notifications-disabled
                            </Badge>
                          )}
                        </ListGroup.Item>
                      );
                    })}
                  </ListGroup>
                )}
              </Card>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Send Message
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddMessageSingle;
