import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Spinner,
  Badge,
  Image,
  Row,
  Col,
  Card,
  Dropdown,
} from "react-bootstrap";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { LinkContainer } from "react-router-bootstrap";
import { DateTime } from "luxon";
import numbro from "numbro";
import RequestStatusModal from "./RequestStatusModal";

const Request = () => {
  let { id } = useParams();

  //State
  const [showRS, setShowRS] = useState(false);

  // Queries & Mutations
  //Ger User
  const { isLoading, error, isError, data } = useQuery({
    queryKey: ["request", id],
    queryFn: () => getRequest({ id: id }),
  });

  if (isLoading) {
    return <Spinner animation="border" />;
  }

  if (isError) {
    return <p className="text-center"> {Functions.handleError(error)}</p>;
  }

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <div className="d-flex flex-row align-items-center">
          <LinkContainer to={"/dashboard"}>
            <p className="text-primary breadcrumb-text fw-bold">Dashboard</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <LinkContainer to={"/dashboard/requests"}>
            <p className="text-primary breadcrumb-text fw-bold">Requests</p>
          </LinkContainer>
          <p className="mx-1 breadcrumb-text fw-bold"> {" > "} </p>
          <p className="breadcrumb-text fw-bold">{id}</p>
        </div>
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div>
            <p className="h2">
              {data?.originDetail?.name} {">"} {data?.destinationDetail?.name}
            </p>
          </div>
          <Dropdown>
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              Actions
            </Dropdown.Toggle>

            <Dropdown.Menu className="bg-white">
              <Dropdown.Item onClick={() => setShowRS(true)}>
                Update Request Status
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <Row className="px-md-5 px-2">
        <Col md={8}>
          <Card className="mb-3 mt-2" bg="white">
            <Card.Body>
              <h4>Request Particulars</h4>
              <div className="border-bottom border-dashed pb-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Departure Date</p>
                  <p>
                    {DateTime.fromISO(data?.departure).toLocaleString(
                      DateTime.DATETIME_MED
                    )}
                  </p>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Bus Type</p>
                  <Badge bg="secondary">{data?.type}</Badge>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Seats Requested</p>
                  <p>{data?.seats}</p>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Total Cost</p>
                  <p>
                    {data?.amount &&
                      numbro(data?.amount).format({
                        thousandSeparated: true,
                      })}{" "}
                    {data?.amount ? " UGX" : "PENDING"}
                  </p>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Additional Notes</p>
                  <p>{data?.notes}</p>
                </div>
              </div>
              <div className="pt-2">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Status</p>
                  <Badge
                    bg={
                      data?.status === "accepted"
                        ? "primary"
                        : data?.status === "pending"
                        ? "secondary"
                        : "danger"
                    }
                  >
                    {data?.status}
                  </Badge>
                </div>
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <p>Is Archived</p>
                  <Badge bg={data?.is_archived ? "primary" : "secondary"}>
                    {data?.is_archived ? "True" : "False"}
                  </Badge>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card bg="white">
            <Card.Body>
              <div className="d-flex flex-row align-items-center pb-4">
                {data?.owner?.picture !== null ? (
                  <Image
                    src={process.env.REACT_APP_IMAGE_URL + data?.owner?.picture}
                    roundedCircle
                    width="100"
                    height="100"
                    className="mr-3"
                  />
                ) : null}
                <div className="ms-3">
                  <h4>{data?.owner?.name}</h4>
                  <p className="mb-0">{data?.owner?.phone}</p>
                  <p className="mb-0">{data?.owner?.gender}</p>
                  <p className="mb-0">
                    Rating{" "}
                    {numbro(data?.owner?.average_rating).format({
                      mantissa: 1,
                    })}{" "}
                    ({data?.owner?.rating_count})
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <RequestStatusModal setShow={setShowRS} show={showRS} id={id} />
    </>
  );
};

export default Request;
