import { useState, useEffect } from "react";
import { Form, Button, InputGroup, Modal, Row, Col } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editPartnerLocation } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { useForm, Controller, useFieldArray } from "react-hook-form";

const EditLocationModal = ({ show, setShow, itemData }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleClose = () => {
    reset();
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false);
  };

  //Form initiation
  const {
    control,
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: {
      id: "",
      name: "",
      coords: "",
      description: "",
      partner_id: "",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "contacts",
  });

  useEffect(() => {
    if (show) {
      reset({
        id: itemData?.id,
        partner_id: itemData?.partner_id,
        name: itemData?.name,
        coords: itemData?.coords,
        description: itemData?.description,
        contacts: itemData?.contacts,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = (data) => {
    editMutation.mutate(data);
  };

  // Queries & Mutations
  const queryClient = useQueryClient();

  //Update Status
  const editMutation = useMutation({
    mutationFn: (data) => editPartnerLocation(data),
    onSuccess: (dataResponse) => {
      setErrorMessage("");
      setSuccessMessage("Location edited successfully.");
      queryClient.invalidateQueries("partners");
    },
    onError(error: any) {
      setSuccessMessage("");
      setErrorMessage(Functions.handleError(error));
    },
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        contentClassName="bg-white"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Partner Location</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
            {successMessage && (
              <span className="text-primary">{successMessage}</span>
            )}
            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Location Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="name"
                {...register("name")}
              />
              {errors?.name && (
                <span className="text-danger">{errors?.name?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Coordinates (Get from Google Maps)</Form.Label>
              <Form.Control
                type="text"
                placeholder="coords"
                {...register("coords")}
              />
              {errors?.coords && (
                <span className="text-danger">{errors?.coords?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="description" className="mt-2">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                {...register("description", {
                  required: "Description is required",
                })}
                rows={3}
                placeholder="Type a description of the location. e.g. Kampala Office"
              />
              {errors?.description && (
                <span className="text-danger">
                  {errors?.description?.message}
                </span>
              )}
            </Form.Group>
            <Form.Group controlId="description" className="mt-2">
              <Form.Label>Contacts</Form.Label>
            </Form.Group>
            {fields.map((item, index) => (
              <div key={item.id}>
                <Row className="mt-2">
                  <Col>
                    <Controller
                      control={control}
                      name={`contacts.${index}.title`}
                      rules={{
                        required: "Title is required.",
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                          type="text"
                          placeholder="Title"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                      )}
                    />
                  </Col>
                  <Col>
                    <Controller
                      control={control}
                      name={`contacts.${index}.name`}
                      rules={{
                        required: "Name is required.",
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                          type="text"
                          placeholder="name"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                      )}
                    />
                  </Col>
                  <Col>
                    <Controller
                      control={control}
                      name={`contacts.${index}.phone`}
                      rules={{
                        required: "Contact is required.",
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Form.Control
                          type="text"
                          placeholder="phone"
                          onChange={onChange}
                          onBlur={onBlur}
                          value={value}
                        />
                      )}
                    />
                  </Col>

                  <Col>
                    <Button onClick={() => remove(index)}>Remove</Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>
                      {errors.contacts?.[index]?.phone && (
                        <span className="text-danger">
                          {errors.contacts?.[index].phone?.message}
                        </span>
                      )}
                      {errors.contacts?.[index]?.name && (
                        <span className="text-danger">
                          {errors.contacts?.[index].name?.message}
                        </span>
                      )}
                      {errors.contacts?.[index]?.title && (
                        <span className="text-danger">
                          {errors.contacts?.[index].title?.message}
                        </span>
                      )}
                    </p>
                  </Col>
                </Row>
              </div>
            ))}
            <Row className="my-2">
              <Col>
                <Button
                  onClick={() => append({ title: "", name: "", phone: "" })}
                >
                  Add Contact
                </Button>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Edit Locations
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditLocationModal;
