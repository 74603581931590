import { useEffect, useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editLocation } from "../../api/mainApi";
import { Functions } from "../../utilities";
import { useForm } from "react-hook-form";

const EditModal = ({ show, setShow, itemData }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const handleClose = () => {
    reset();
    setSuccessMessage("");
    setErrorMessage("");
    setShow(false);
  };

  //Form initiation
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    reValidateMode: "onSubmit",
    // reValidateMode: "onChange",
    defaultValues: {
      name: "",
      code: "",
      coords: "",
      id: "",
    },
  });

  useEffect(() => {
    if (show) {
      reset({
        name: itemData?.name,
        code: itemData?.code,
        coords: itemData?.coords,
        id: itemData?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const onSubmit = (data) => {
    editMutation.mutate(data);
  };

  // Queries & Mutations
  const queryClient = useQueryClient();

  //Update Status
  const editMutation = useMutation({
    mutationFn: (data) => editLocation(data),
    onSuccess: (dataResponse) => {
      setErrorMessage("");
      setSuccessMessage("Location edited successfully.");
      queryClient.invalidateQueries("locations");
    },
    onError(error: any) {
      setSuccessMessage("");
      setErrorMessage(Functions.handleError(error));
    },
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        contentClassName="bg-white"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Location</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            {errorMessage && (
              <span className="text-danger">{errorMessage}</span>
            )}
            {successMessage && (
              <span className="text-primary">{successMessage}</span>
            )}
            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="location name"
                {...register("name")}
              />
              {errors?.name && (
                <span className="text-danger">{errors?.name?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="location code"
                {...register("code")}
              />
              {errors?.code && (
                <span className="text-danger">{errors?.code?.message}</span>
              )}
            </Form.Group>
            <Form.Group controlId="name" className="mt-2">
              <Form.Label>Coordinates</Form.Label>
              <Form.Control
                type="text"
                placeholder="location coordinates"
                {...register("coords")}
              />
              {errors?.coords && (
                <span className="text-danger">{errors?.coords?.message}</span>
              )}
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save Changes
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default EditModal;
