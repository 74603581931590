import { Card, Col } from "react-bootstrap";

const StatCard = ({ title, stat, subStats }) => {
  return (
    <Col key={title} className="d-flex">
      <Card className="bg-white flex-fill">
        <Card.Body>
          <p className="text-primary breadcrumb-text fw-normal"> {title}</p>
          <Card.Text className="fs-2">{stat}</Card.Text>
          {subStats && (
            <div>
              {subStats?.map((item) => {
                return (
                  <div
                    className="d-flex flex-row justify-content-between align-items-center border-top border-dashed"
                    key={item?.title}
                  >
                    <p className="stat-text text-muted mb-1">{item?.title}</p>
                    <p className="fw-bold mb-1 stat-text">{item?.stat}</p>
                  </div>
                );
              })}
            </div>
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default StatCard;
