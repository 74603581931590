import React from "react";
import { useQuery } from "@tanstack/react-query";
import { Row } from "react-bootstrap";
import {
  getRideStats,
  getUserStats,
  getBookingStats,
  getMessageStats,
  getTransactionStats,
} from "../../api/mainApi";
import StatCard from "./StatCard";

const Home = () => {
  //Queries & Mutations
  //Get Ride Stats
  const { isSuccess: isSuccessRideStats, data: dataRideStats } = useQuery({
    queryKey: ["rideStats"],
    queryFn: () => getRideStats(),
  });

  //Get User Stats
  const { isSuccess: isSuccessUserStats, data: dataUserStats } = useQuery({
    queryKey: ["userStats"],
    queryFn: () => getUserStats(),
  });

  //Get Booking Stats
  const { isSuccess: isSuccessBookingStats, data: dataBookingStats } = useQuery(
    {
      queryKey: ["bookingStats"],
      queryFn: () => getBookingStats(),
    }
  );

  //Get Message Stats
  const { isSuccess: isSuccessMessageStats, data: dataMessageStats } = useQuery(
    {
      queryKey: ["messageStats"],
      queryFn: () => getMessageStats(),
    }
  );

  //Get Transaction Stats
  const { isSuccess: isSuccessTransactionStats, data: dataTransactionStats } =
    useQuery({
      queryKey: ["transactionStats"],
      queryFn: () => getTransactionStats(),
    });

  return (
    <>
      <div className="px-md-5 py-md-3 px-2 py-1">
        <p className="h2 mb-3">Cabbily Dashboard</p>
        <div className="border-bottom mb-3">
          <p className="h4">Users</p>
          <p className="mb-0 text-secondary">How are users doing</p>
        </div>
        {isSuccessUserStats && (
          <Row xs={1} md={4} className="g-4">
            <StatCard
              title="USER ACTIVITY"
              subStats={[
                { title: "New Users", stat: dataUserStats?.new_users },
                {
                  title: "Weekly Active",
                  stat: dataUserStats?.weekly_active,
                },
                {
                  title: "Monthly Active",
                  stat: dataUserStats?.monthly_active,
                },
                {
                  title: "Quarterly Active",
                  stat: dataUserStats?.quarterly_active,
                },
                {
                  title: "Semi-Annual Active",
                  stat: dataUserStats?.semi_year_active,
                },
                {
                  title: "Yearly Active",
                  stat: dataUserStats?.yearly_active,
                },
              ]}
            />
            <StatCard
              title="TOTAL USERS"
              stat={dataUserStats?.total_users}
              subStats={[
                {
                  title: "Female",
                  stat: dataUserStats?.total_gender_female,
                },
                { title: "Male", stat: dataUserStats?.total_gender_male },
                {
                  title: "Unspecified",
                  stat: dataUserStats?.total_gender_unspecified,
                },
                {
                  title: "Deleted",
                  stat: dataUserStats?.total_deleted_users,
                },
              ]}
            />
            <StatCard
              title="VERIFIED PASSENGERS"
              stat={dataUserStats?.total_verified_passengers}
              subStats={[
                {
                  title: "Pending",
                  stat: dataUserStats?.total_pending_passengers,
                },
                {
                  title: "Blocked",
                  stat: dataUserStats?.total_blocked_passengers,
                },
              ]}
            />
            <StatCard
              title="VERIFIED DRIVERS"
              stat={dataUserStats?.total_verified_drivers}
              subStats={[
                {
                  title: "Pending",
                  stat: dataUserStats?.total_pending_drivers,
                },
                {
                  title: "Blocked",
                  stat: dataUserStats?.total_blocked_drivers,
                },
              ]}
            />
          </Row>
        )}

        <div className="border-bottom mb-3 mt-3">
          <p className="h4">Rides</p>
          <p className="mb-0 text-secondary">How are user rides performing</p>
        </div>

        {isSuccessRideStats && (
          <Row xs={1} md={4} className="g-4">
            <StatCard
              title="ONGOING RIDES"
              stat={dataRideStats?.started_rides}
              subStats={[
                { title: "Pending", stat: dataRideStats?.pending_rides },
              ]}
            />
            <StatCard title="TOTAL RIDES" stat={dataRideStats?.total_rides} />

            <StatCard
              title="COMPLETED RIDES"
              stat={dataRideStats?.completed_rides}
              subStats={[
                {
                  title: "Cancelled",
                  stat: dataRideStats?.cancelled_rides,
                },
                {
                  title: "Expired",
                  stat: dataRideStats?.expired_rides,
                },
              ]}
            />
          </Row>
        )}

        <div className="border-bottom mb-3 mt-3">
          <p className="h4">Bookings</p>
          <p className="mb-0 text-secondary">How are bookings performing</p>
        </div>

        {isSuccessBookingStats && (
          <Row xs={1} md={4} className="g-4">
            <StatCard
              title="ONGOING BOOKINGS"
              stat={dataBookingStats?.accepted_bookings}
              subStats={[
                {
                  title: "Pending",
                  stat: dataBookingStats?.pending_bookings,
                },
              ]}
            />
            <StatCard
              title="TOTAL BOOKINGS"
              stat={dataBookingStats?.total_bookings}
            />
            <StatCard
              title="COMPLETED BOOKINGS"
              stat={dataBookingStats?.completed_bookings}
              subStats={[
                {
                  title: "Rejected",
                  stat: dataBookingStats?.rejected_bookings,
                },
                {
                  title: "Cancelled",
                  stat: dataBookingStats?.cancelled_bookings,
                },
              ]}
            />
          </Row>
        )}

        <div className="border-bottom mb-3 mt-3">
          <p className="h4">Transactions</p>
          <p className="mb-0 text-secondary">
            Payment Central. All Payments happening in cabbily.
          </p>
        </div>

        {isSuccessTransactionStats && (
          <Row xs={1} md={4} className="g-4">
            <StatCard
              title="COMPLETED TRANSACTIONS"
              stat={dataTransactionStats?.total_completed}
              subStats={[
                {
                  title: "Pending",
                  stat: dataTransactionStats?.total_pending,
                },
                {
                  title: "Failed",
                  stat: dataTransactionStats?.total_failed,
                },
              ]}
            />
            <StatCard
              title="TOTAL CASH"
              subStats={[
                {
                  title: "Deposits",
                  stat: `${dataTransactionStats?.total_deposits_cash} UGX`,
                },
                {
                  title: "Withdraws",
                  stat: `${dataTransactionStats?.total_withdraw_cash} UGX`,
                },
                {
                  title: "Payments",
                  stat: `${dataTransactionStats?.total_payment_cash} UGX`,
                },
                {
                  title: "Receipts",
                  stat: `${dataTransactionStats?.total_receipt_cash} UGX`,
                },
              ]}
            />
            <StatCard
              title="TOTAL TRANSACTIONS"
              stat={dataTransactionStats?.total_transactions}
              subStats={[
                {
                  title: "Deposits",
                  stat: dataTransactionStats?.total_deposits,
                },
                {
                  title: "Withdraws",
                  stat: dataTransactionStats?.total_withdraws,
                },
                {
                  title: "Payments",
                  stat: dataTransactionStats?.total_payments,
                },
                {
                  title: "Receipts",
                  stat: dataTransactionStats?.total_receipts,
                },
                {
                  title: "Refunds",
                  stat: dataTransactionStats?.total_refunds,
                },
              ]}
            />
            <StatCard
              title="TRANSACTION METHODS"
              subStats={[
                {
                  title: "Wallet",
                  stat: dataTransactionStats?.total_wallet,
                },
                {
                  title: "Cash",
                  stat: dataTransactionStats?.total_cash,
                },
              ]}
            />
          </Row>
        )}
        <div className="border-bottom mb-3 mt-3">
          <p className="h4">Messaging</p>
          <p className="mb-0 text-secondary">
            SMS & Notifications send by admin.
          </p>
        </div>

        {isSuccessMessageStats && (
          <Row xs={1} md={4} className="g-4">
            <StatCard
              title="TOTAL MESSAGES"
              stat={dataMessageStats?.total_messages}
              subStats={[
                {
                  title: "SMS",
                  stat: dataMessageStats?.total_sms_only,
                },
                {
                  title: "Notifications",
                  stat: dataMessageStats?.total_notifications_only,
                },
              ]}
            />
            <StatCard
              title="SENT TO DRIVERS"
              subStats={[
                {
                  title: "Pending",
                  stat: dataMessageStats?.total_pending_drivers,
                },
                {
                  title: "Verified",
                  stat: dataMessageStats?.total_verified_drivers,
                },
                {
                  title: "Blocked",
                  stat: dataMessageStats?.total_blocked_drivers,
                },
              ]}
            />
            <StatCard
              title="SENT TO PASSENGERS"
              subStats={[
                {
                  title: "Pending",
                  stat: dataMessageStats?.total_pending_passengers,
                },
                {
                  title: "Verified",
                  stat: dataMessageStats?.total_verified_passengers,
                },
                {
                  title: "Blocked",
                  stat: dataMessageStats?.total_blocked_passengers,
                },
              ]}
            />
            <StatCard
              title="SENT TO PROFILE"
              subStats={[
                {
                  title: "Complete Profile",
                  stat: dataMessageStats?.total_complete_profile,
                },
                {
                  title: "Incomplete Profile",
                  stat: dataMessageStats?.total_incomplete_profile,
                },
              ]}
            />
          </Row>
        )}
      </div>
    </>
  );
};

export default Home;
